import React, { useEffect, useState,useGlobal } from "reactn";
import { Row, Col, Form, Button, Card, message, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import api from '../../service/index'
import { Link } from 'react-router-dom';

const PageReservationSettings = (props) => {
  let history = useHistory()
  let id = "reservationSettings"
  let [data, setData] = useState({});
  let [errors, ] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms"); 
   
  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if (id) {
      await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
        setData(result);
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }

  let save = async () => {
    if (id) {
      api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
          history.push('/settings/reservationAllSettings')
        }
        else
          message.error(languagesCms.ERROR_SAVE_RECORD, 2);
      })
    } else {
      api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
          history.push('/settings/reservationAllSettings')
        } else {
          message.error(result_message.message, 2);
        }
      })
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Rezervasyon Ayarları</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings/reservationAllSettings">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={"Rezervasyon Gün Ayarları"}>
            <Form layout="horizontal" size={"large"} onFinish={save}>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item label={languagesCms.START_MON} help={errors.whichMonday} validateStatus={errors.whichMonday ? 'error' : 'success'}>
                    <InputNumber name="whichMonday" value={data.whichMonday} onChange={v => setData({ ...data, whichMonday: v })} />
                    <br /><small>{languagesCms.RESERVATION_MON}</small>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item label={languagesCms.LENGTH_DAY} help={errors.reserveDays} validateStatus={errors.reserveDays ? 'error' : 'success'}>
                    <InputNumber name="reserveDays" value={data.reserveDays} onChange={v => setData({ ...data, reserveDays: v })} />
                    <br /><small>{languagesCms.RESERVATION_START_DATE_TIME}</small>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item label={languagesCms.FINISH_DAY} help={errors.reservationClosingDay} validateStatus={errors.reservationClosingDay ? 'error' : 'success'}>
                    <InputNumber name="reservationClosingDay" value={data.reservationClosingDay} onChange={v => setData({ ...data, reservationClosingDay: v })} />
                    <br /><small>{languagesCms.RESERVATION_FINISH_DATE_TIME}</small>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                  <ul>
                    <p><b>{languagesCms.START}</b></p>
                    <li><b>{languagesCms.ETC} :</b> {languagesCms.RESERVATION_START_MESSAGE1} </li>
                    <li><b>{languagesCms.ETC} :</b> {languagesCms.RESERVATION_START_MESSAGE2} </li>
                    <br />
                    <p><b>{languagesCms.LENGTH}</b></p>
                    <li><b>{languagesCms.ETC} :</b> {languagesCms.RESERVATION_LENGTH_MESSAGE1} </li>
                    <li><b>{languagesCms.ETC} :</b> {languagesCms.RESERVATION_LENGTH_MESSAGE2} </li>
                    <br />
                    <p><b>{languagesCms.CLOSING}</b></p>
                    <li><b>{languagesCms.ETC} :</b> {languagesCms.RESERVATION_CLOSING_MESSAGE1} </li>
                    <li><b>{languagesCms.ETC} :</b> {languagesCms.RESERVATION_CLOSING_MESSAGE2} </li>
                  </ul>
                  <br />
                </Col>
              </Row>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};

export default PageReservationSettings;
