import React, { useEffect, useState,useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row,Col,Form, Input, Button, Card, message} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import api from '../../service/index'
import { Link } from 'react-router-dom';

const LikeTypesDetail = (props) => {

  let params = useParams()
  let history = useHistory()
  let id = params.id
  let subId = params.subId

  let [items, setItems] = useState({});
  let [data, setData] = useState({});
  let [errors, ] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms"); 
   
  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if(id) {
      await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
        setData(result.items[subId]);  
        setItems(result) 
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }
  
  let save = async () => {
      if(id) {
          items.items[subId] = data
        api.put("/rest/settings/" + id, items).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/settings/like_types')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/settings/like_types')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }       
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Like Types</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings/like_types">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={"large"} onFinish={save}>


            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="id"  help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                  <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Title"  help={errors.title} validateStatus={errors.title ? 'error' : 'success'}>
                  <Input name="title"  value={data.title} onChange={e => setData({ ...data, title: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Type"  help={errors.type} validateStatus={errors.type ? 'error' : 'success'}>
                  <Input name="type"  value={data.type} onChange={e => setData({ ...data, type: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="Icon Url"  help={errors.image} validateStatus={errors.image ? 'error' : 'success'}>
                  <Input name="image"  value={data.image} onChange={e => setData({ ...data, image: e.target.value })} />
                  <a target={"_blank"} style={{color: "red"}} size="sm" href={data.image ? data.image : null }>{languagesCms.VIEW}</a>
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};

export default LikeTypesDetail;
