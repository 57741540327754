import React, { useEffect, useState } from "reactn";
import { Card, Button, Row, Col, List, Avatar, Typography } from 'antd';
import api from '../service/index'
import { Link, useParams } from 'react-router-dom';
import qs from 'qs';
import { LeftOutlined } from "@ant-design/icons";
import useInterval from 'react-useinterval';

var QRCode = require('qrcode.react');

let Barcod = (props) => {

    let [data, setData] = useState([]);
    let params = useParams()
    let id = params.id !== "add" ? params.id : false;
    let [loading, setLoading] = useState(id ? true : false);
    let [time, setTime] = useState(3);

    let get = async (page) => {
        let shortString = 'order';
        let _params = { sort: shortString, ...page, };
        let restData = await api.get(`/rest/lessons/${qs.stringify(_params)}`, { _params }).then(({ data }) => {
            if (data.result) {
                setData(restData);
                setLoading(false)
            }
        });
    }

    useEffect(() => {
        if (id) {
            setLoading(false)
            get()
        }
    }, [id, params]);

    useInterval(() => {
        setTime(currentCount => time === 0 ? 3 : currentCount - 1);
        if (time === 0)
            get()
    }, 1000)

    let setQrSize = () => {
        let width = window.innerWidth;
        return width / 4;
    };

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>SERTİFİKALI İLKYARDIM</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/lessons">
                        <Button type="light" icon={<LeftOutlined />} size="large">Geri</Button>
                    </Link>
                </div>
            </div>

            {!loading &&
                <div className="form-wrap" loading={loading}>
                    <Card>
                        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                            <Col flex="1 1 800px">
                                <Card>
                                    <Col span="24"><div className="timer__bg">{time}</div></Col>
                                    <Col span="24" offset={6}>
                                        <QRCode value={JSON.stringify({ lessonId: id, state: data.state, joinsLength: (data.joins ? data.joins.length : 0) })} size={setQrSize()} />
                                    </Col>
                                </Card>
                            </Col>

                            <Col flex="1 1 500px">
                                <Card>
                                    <Col className="users__title">Katılan Kişi Sayısı: {data.joins ? data.joins.length : 0} Kişi</Col>
                                    <List
                                        style={{ width: 'calc(100% - 50px)', margin: 20 }}
                                        bordered
                                        dataSource={data.joins}
                                        renderItem={data => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={<Avatar size='large' src={data.avatar && data.avatar.thumb} />}
                                                    title={<Typography.Text strong style={{ fontSize: 16 }}>{data.name + ' ' + (data.lastname ? data.lastname : '')}</Typography.Text>}
                                                    description={<Typography.Text>{data.position} / {data.department} / {data.location}</Typography.Text>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </div>
            }
        </div>
    );
};

export default Barcod;
