import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  SnippetsOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';

const PhotoAlbum = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'order';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'title';
    }
    let restData = await api
      .get(`/rest/photoAlbumCategories?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <CheckOutlined />;
            else item.active = <CloseOutlined />;

            if (!item.hasOwnProperty('groups')) {
              item.groups = [];
            }
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/photoAlbumCategories/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };
  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/photoAlbumCategories/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: languagesCms.PHOTO,
      dataIndex: 'imageurl',
      key: 'imageurl',
      render: (text, record) => {
        return (
          <div style={{ width: '100px' }}>
            <img src={text} style={{ width: '100%' }} alt="" />
          </div>
        );
      },
    },
    {
      title: languagesCms.TITLE,
      dataIndex: 'langs',
      key: 'langs',
      render: (langs) =>
        langs.map((l, i) => (
          <div key={i} style={{ marginBottom: 5 }}>
            {l.title} - {l.lang}
          </div>
        )),
    },
    {
      title: languagesCms.ORDER,
      dataIndex: 'order',
      key: 'order',
      sorter: (a, b) => a.order - b.order,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIVE,
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.GROUP,
      dataIndex: 'groups',
      key: 'groups',
      sorter: (a, b) => a.groups - b.groups,
      sortDirections: ['descend', 'ascend'],
      render: (groups) =>
        groups.length > 0 &&
        groups.map((e) => <div style={{ marginBottom: 5 }}>{e.name}</div>),
    },
    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (

        <Space size="small">
          {record.type=='category' &&
            <Link to={'/photoAlbumSubCategory/list/' + record._id}>
              <Button icon={<SnippetsOutlined />}>
                {isSmall ? '' : "Sub List"}
              </Button>
            </Link>          
          }

          {record._id !== 'postwall' && (
            <Link to={'/photoAlbum/detail/' + record._id}>
              <Button icon={<SnippetsOutlined />}>
                {isSmall ? '' : languagesCms.DETAIL}
              </Button>
            </Link>
          )}

          <Link to={'/photoAlbum/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>

          {record._id !== 'postwall' && (
            <Popconfirm
              onConfirm={() => deleteRow(record._id)}
              title={languagesCms.CHECK_DELETE}
              okText={languagesCms.OK_TEXT}
              cancelText={languagesCms.CANCEL_TEXT}
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                {isSmall ? '' : languagesCms.DELETE}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}
          <Link to="/photoAlbum/add">
            <Button type="light" icon={<PlusOutlined />} size="large">
              {isSmall ? '' : languagesCms.ADD_CATEGORY}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH_ALBUM}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default PhotoAlbum;
