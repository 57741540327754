import React from 'react';
import { Select } from 'antd';

export default (props) => {
  let { record, setRecord, name, mode } = props;
  let languagesCms = props.languagesCms;
  return (
    <Select
      value={record[name] || []}
      mode={mode}
      placeholder={languagesCms.SELECT_ESTATE_TYPE}
      onChange={(v) => setRecord({ ...record, [name]: v })}
    >
      <Select.Option value="Satılık">{languagesCms.SALE}</Select.Option>
      <Select.Option value="Kiralık">{languagesCms.RENT}</Select.Option>
    </Select>
  );
};
