import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Card, message, Select,DatePicker } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { MapInput, CoordinateTypesPicker,DbSelect, DbSelectPlan } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import moment from 'moment';

const { Option } = Select;

const FlightServiceDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let newRecord = {
    pnr: '',
    location: '',
    description: '',
    date: '',
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let [modules] = useGlobal('modules');
  let [languagesCms] = useGlobal('languagesCms');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

   
  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/flightService/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.pnr === null || data.pnr.length === 0)
      errors.code = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/flightService/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/flight/service');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/flightService', data)
          .then(({ data: { result, result_message } }) => {
            console.log("result",result);
            console.log("result_message",result_message);

            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/flight/service');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Servis</h1>
        </div>
        <div className="list-buttons">
          <Link to="/flight/service">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
                 
          <Row direction="row"> 
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label='Kalkış Yeri'
                    required
                    help={errors.location}
                    validateStatus={errors.location ? 'error' : 'success'}
                  >
                    <Input
                      name="location"
                      value={data.location}
                      onChange={(e) => setData({ ...data, location: e.target.value })}
                    />
                  </Form.Item>
              </Col>  

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label='Kalkış Tarihi'
                  required
                  help={errors.date}
                  validateStatus={errors.date ? 'error' : 'success'}
                >
                <DatePicker
                    format="DD-MM-YYYY HH:mm"
                    showTime
                    defaultValue={moment(data.date || new Date())}
                    style={{ width: 250, marginLeft: 5 }}
                    placeholder={languagesCms.SELECT_EARLIEST_DATE}
                    onChange={(e) => setData({ ...data, date: e })}
                  />
                </Form.Item>
              </Col>

            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label='PNR'
                    required
                    help={errors.pnr}
                    validateStatus={errors.pnr ? 'error' : 'success'}
                  >
                   <Input
                      name="pnr"
                      value={data.pnr}
                      onChange={(e) => setData({ ...data, pnr: e.target.value })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label='Açıklama'
                    required
                    help={errors.description}
                    validateStatus={errors.description ? 'error' : 'success'}
                  >
                    <Input
                      name="description"
                      value={data.description}
                      onChange={(e) => setData({ ...data, description: e.target.value })}
                    />
                  </Form.Item>
              </Col>  
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default FlightServiceDetail;
