import React, { useEffect, useState, useGlobal } from "reactn";

import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Card, Switch, message, InputNumber, DatePicker } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { GroupPicker} from '../components';
import moment from 'moment';

import api from '../service/index'
import { Link } from 'react-router-dom';

import { useCallback } from "react";


const ActivityDetail = (props) => {
  let params = useParams()
  let history = useHistory()
  let activity = params.id !== "add" ? params.id : false;
  let id = params.subid !== "add" ? params.subid : false;
 
  let [modules] = useGlobal("modules");
  let [languagesCms] = useGlobal("languagesCms"); 
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }

  let newRecord = {
    name: '',
    active: true,
    category: params.id,
    attendees: [],
    startDate: new Date(),
    endDate: new Date(),
    groups:[]
  }

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [isSmall, ] = useGlobal('isSmall')

  let [validationCheck, setValidationCheck] = useState(false)

   
  useEffect(() => {
    if (modules) {
        setLoading(true)
        get();
    }
}, [])

  let get = async () => {
    if (id) {
      await api.get("/rest/activity/" + id).then(({ data: { result, result_message } }) => {
        setData(result);
        setLoading(false)
      });
    } else {

      setLoading(false)
    }
  }

  let validate = useCallback(() => {
    let errors = {};

    if (data.category == null || data.category.length === 0)
      errors.category = languagesCms.ENFORCED

    if (data.name == null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED



    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);


  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if (id) {
        api.put("/rest/activity/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/activity/detail/' + activity)
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/activity", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/activity/detail/' + activity)
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to={"/activity/detail/" + activity}>
            <Button type="light" icon={<LeftOutlined />} size={'large'}>{!isSmall && languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
          <Form layout="horizontal" size={"large"} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.GROUP}>
                  <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.STATUS} required help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                  <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.TITLE} required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                  <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.START_DATE} required help={errors.startDate} validateStatus={errors.startDate ? 'error' : 'success'}>
                  <DatePicker defaultValue={() => moment(data.startDate)} onChange={v => setData({ ...data, startDate: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.END_DATE} required help={errors.endDate} validateStatus={errors.endDate ? 'error' : 'success'}>
                  <DatePicker defaultValue={() => moment(data.endDate)} onChange={v => setData({ ...data, endDate: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
                </Form.Item>
              </Col>

            </Row>
            <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.QUOTA} help={errors.quota} validateStatus={errors.quota ? 'error' : 'success'}>
                  <InputNumber name="quota" value={data.quota} onChange={v => setData({ ...data, quota: v })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={24}>
                <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>

      </div>
    </div>
  );
};



export default ActivityDetail;
