import React, { useEffect, useState,useGlobal } from "reactn";
import { Row, Col, Form, Input, Button, Card, message, Alert,Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import api from '../../service/index'
import { Link } from 'react-router-dom';

const JobStartDateSettings = (props) => {
  let history = useHistory()
  let id = "jobStartDateSettings"
  let [data, setData] = useState({});
  let [errors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms"); 
   
  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if (id) {
      await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
        setData(result);
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }

  let save = async () => {
    if (id) {
      api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
          history.push('/settings')
        }
        else
          message.error(languagesCms.ERROR_SAVE_RECORD, 2);
      })
    } else {
      api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
          history.push('/settings')
        } else {
          message.error(result_message.message, 2);
        }
      })
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Job Start Date Settings</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={"large"} onFinish={save}>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label="id" help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                    <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label={languagesCms.SHOW_POSITION} help={errors.icon} validateStatus={errors.icon ? 'error' : 'success'}>
                    <Switch checked={data.showPosition ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, showPosition: v })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label="Icon Url" help={errors.icon} validateStatus={errors.icon ? 'error' : 'success'}>
                    <Input name="icon" value={data.icon} onChange={e => setData({ ...data, icon: e.target.value })} />
                    <Alert message={languagesCms.JOB_START_ICON_MESSAGE} banner />
                    <a target={"_blank"} style={{color: "red"}} size="sm" href={data.icon ? data.icon : null }>{languagesCms.VIEW}</a>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label="Ios image Url" help={errors.iosImage} validateStatus={errors.iosImage ? 'error' : 'success'}>
                    <Input name="iosImage" value={data.iosImage} onChange={e => setData({ ...data, iosImage: e.target.value })} />
                    <a target={"_blank"} style={{color: "red"}} size="sm" href={data.iosImage ? data.iosImage : null }>{languagesCms.VIEW}</a>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label="Andorid image Url" help={errors.androidImage} validateStatus={errors.androidImage ? 'error' : 'success'}>
                    <Input name="androidImage" value={data.androidImage} onChange={e => setData({ ...data, androidImage: e.target.value })} />
                    <a target={"_blank"} style={{color: "red"}} size="sm" href={data.androidImage ? data.androidImage : null }>{languagesCms.VIEW}</a>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="Progress Color" help={errors.progressColor} validateStatus={errors.progressColor ? 'error' : 'success'}>
                    <Input name="progressColor" value={data.progressColor} onChange={e => setData({ ...data, progressColor: e.target.value })} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="delete Color" help={errors.deleteColor} validateStatus={errors.deleteColor ? 'error' : 'success'}>
                    <Input name="deleteColor" value={data.deleteColor} onChange={e => setData({ ...data, deleteColor: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="Title Color" help={errors.titleColor} validateStatus={errors.titleColor ? 'error' : 'success'}>
                    <Input name="titleColor" value={data.titleColor} onChange={e => setData({ ...data, titleColor: e.target.value })} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="nameColor" help={errors.nameColor} validateStatus={errors.nameColor ? 'error' : 'success'}>
                    <Input name="nameColor" value={data.nameColor} onChange={e => setData({ ...data, nameColor: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="Send Input Color" help={errors.sendInputColor} validateStatus={errors.sendInputColor ? 'error' : 'success'}>
                    <Input name="sendInputColor" value={data.sendInputColor} onChange={e => setData({ ...data, sendInputColor: e.target.value })} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="Send Button Color" help={errors.sendButtonColor} validateStatus={errors.sendButtonColor ? 'error' : 'success'}>
                    <Input name="sendButtonColor" value={data.sendButtonColor} onChange={e => setData({ ...data, sendButtonColor: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="messageTextColor" help={errors.messageTextColor} validateStatus={errors.messageTextColor ? 'error' : 'success'}>
                    <Input name="messageTextColor" value={data.messageTextColor} onChange={e => setData({ ...data, messageTextColor: e.target.value })} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="Message Background Color" help={errors.messageBackgroundColor} validateStatus={errors.messageBackgroundColor ? 'error' : 'success'}>
                    <Input name="messageBackgroundColor" value={data.messageBackgroundColor} onChange={e => setData({ ...data, messageBackgroundColor: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </Card>
        }
      </div>
    </div>
  );
};

export default JobStartDateSettings;
