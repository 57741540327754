import React, { useState, useMemo } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name, mode } = props;
  let [brands, setBrands] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 100 };
  let languagesCms = props.languagesCms;

  useMemo(() => {
    api
      .get(`/rest/secondhandCarBrands?${qs.stringify(_params)}`)
      .then(({ data }) => {
        if (data && data.result && data.result.rows.length > 0) {
          let dbBrands = data.result.rows.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
          setBrands(dbBrands);
        }
      });
  }, []);

  return (
    <Select
      value={record[name] || []}
      mode={mode}
      placeholder={languagesCms.SELECT_CAR}
      onChange={(v) => setRecord({ ...record, [name]: v })}
    >
      {brands &&
        brands.map((b) => (
          <Select.Option key={b.name} value={b.name}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
