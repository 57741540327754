import React, { useState, useEffect, useRef, useGlobal } from 'react';
import {
  Input,
  Card,
  Form,
  Row,
  Col,
  Button,
  message,
  Modal,
  Select,
  Popconfirm,
  DatePicker,
  Switch,
} from 'antd';
import ImageMapper from 'react-image-mapper';
import api from './../service';
import qs from 'qs';
import Parse from 'parse';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';
import { ReservationUsersPicker } from '../components';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
let PlanCoordinates = Parse.Object.extend('PlanCoordinates');
let Reservation = Parse.Object.extend('Reservation');
let User = Parse.Object.extend('User');

export default (props) => {
  let mapper = useRef();
  let { record, name, planId, areas, setAreas, sideRights, nowDate } = props;
  let [coords, setCoords] = useState(null);
  let [date, setDate] = useState(moment(new Date()));
  let [modal, setModal] = useState(null);
  let [startDate, setStartDate] = useState(moment(new Date()));
  let [endDate, setEndDate] = useState(moment(new Date()));
  let [parseServerURL, setParseServerURL] = useState(null);
  let [languagesCms] = useGlobal('languagesCms');
  let [parseAppId, setParseAppId] = useState(null);
  let [map, setMap] = useState({
    name: 'my-map',
    areas: areas,
  });

  Parse.initialize(parseAppId);
  Parse.serverURL = parseServerURL;

  //parse bilgilerinin çekilmesi
  useEffect(() => {
    api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
      if (data.result.rows) {
        data.result.rows.forEach((element) => {
          if (element._id === 'customization') {
            setParseServerURL(element.parseServerURL);
            setParseAppId(element.parseAppId);
          }
        });
      }
    });

    setStartDate(moment(nowDate + ' 00:00'));
    setEndDate(moment(nowDate + ' 23:59'));
  }, []);

  //areas her yenilendiğinde kullanılan map bilgisinin de güncellenmesi için
  useEffect(() => {
    setMap({
      name: 'my-map',
      areas: areas,
    });
  }, [areas]);

  //pop up açılması için doldurulması gerekn bilgiler
  let clickedArea = async (area) => {
    let coordinate = {};
    let coordinateR = {};
    //areadan gelen koordinatlara göre bilgilerin çekilmesi
    let queryCoordinates = new Parse.Query(PlanCoordinates);
    await queryCoordinates
      .get(area.id)
      .then(function (result) {
        coordinate = result;
        coordinateR = { ...result.attributes, id: result.id };
      })
      .catch(function (error) {
        coordinate = {};
        coordinateR = {};
      });

    //rezervasyon varsa rezervasyon bilgilerinin çekilmesi
    if (area.reservationId) {
      let queryReservation = new Parse.Query(Reservation);
      queryReservation.containedIn('objectId', [area.reservationId]);
      await queryReservation
        .find()
        .then(function (results) {
          setCoords({
            ...coordinateR,
            users: results[0].attributes.users.id,
            reservationId: results[0].id,
            lunch: results[0].attributes.lunch,
            service: results[0].attributes.service,
          });
        })
        .catch(function (error) {
          setCoords({ coordinateR });
        });
    } else {
      setCoords({ ...coordinate.attributes, id: coordinate.id });
    }
  };

  let clickOk = async (reservation) => {
    if (reservation.users) {
      let user = '';
      let message = 'Rezervasyon bilgileri kaydediliyor, onaylıyor muzunuz?';

      let query = new Parse.Query(Reservation);
      query.equalTo('itemId', reservation.id);
      query.greaterThanOrEqualTo('date', startDate._d);
      query.lessThanOrEqualTo('date', endDate._d);
      await query.find().then(
        (object) => {
          object.forEach((element) => {
            if (element) {
              let name =
                dayjs(element.attributes.date).format('DD-MM-YYYY').toString() +
                ' - ' +
                [
                  element.attributes.users.attributes.name,
                  element.attributes.users.attributes.lastName,
                ]
                  .filter((x) => x)
                  .join(' ');

              user = [user, name].filter((x) => x).join(', ');
            }
          });
        },
        (error) => {
          message.error('Kullanıcı bilgilerine ulaşılamadı.', 2);
        },
      );

      if (user !== '') {
        message =
          'Seçtiğiniz tarihler arasında, seçtiğiniz masada aşagıdaki kullanıcılara ait rezervasyonlar vardır. Devam ederseniz mevcut rezervasyonlar iptal oalcaktır. Yine de ilerlemek istiyor musunuz?';
      }

      setModal({
        message: message,
        users: user,
        reservation: reservation,
      });
    } else message.error('Kullanıcı seçmediniz!', 2);
  };

  let clickedOk = async (reservation) => {
    let user = {};
    let planCoordinates = {};

    //rezervasyon yapılmak istenen kullanıcı bilgileri alınıyor
    let query = new Parse.Query(User);
    query.equalTo('objectId', reservation.users);
    await query.first().then(
      (object) => {
        user = object;
      },
      (error) => {
        message.error('Kullanıcı bilgilerine ulaşılamadı.', 2);
      },
    );

    //rezervasyon yapılmak istenen kordinat bilgileri alınıyor
    let queryCoordinates = new Parse.Query(PlanCoordinates);
    queryCoordinates.equalTo('objectId', reservation.id);
    await queryCoordinates.first().then(
      (object) => {
        planCoordinates = object;
      },
      (error) => {
        message.error('Oturma alanı bilgilerine ulaşılamadı.', 2);
      },
    );

    if (user) {
      let query = new Parse.Query(Reservation);
      query.equalTo('itemId', reservation.id);
      query.greaterThanOrEqualTo('date', startDate._d);
      query.lessThanOrEqualTo('date', endDate._d);
      await query.find().then(
        (object) => {
          object.forEach((element) => {
            if (element) {
              element.destroy().then(
                (object) => {
                  message.success('Diğer kayıtlar silindi', 2);
                },
                (error) => {
                  message.error(languagesCms.ERROR_DELETE, 2);
                },
              );
            }
          });
        },
        (error) => {
          message.error('Kullanıcı bilgilerine ulaşılamadı.', 2);
        },
      );

      for (let m = startDate; m.isBefore(endDate); m.add(1, 'days')) {
        let d = moment(m.format('YYYY-MM-DD')).add(14, 'hours');

        //eklenecek rezervasyon bilgileri toplanıyor
        let myReservation = new Reservation();
        let myReservationData = {
          floorId: planId,
          itemId: reservation.id,
          PlanCoordinates: planCoordinates,
          users: user,
          userId: user.attributes.user_id,
          status: 'Reserved',
          date: d._d,
          lunch: reservation.lunch || false,
          service: reservation.service || false,
          isReservationLunch: false,
          reservationTitle:
            record.placeId.attributes.name +
            '/' +
            record.title +
            '/' +
            planCoordinates.attributes.name,
        };

        //rezervasyon bilgileri ekleniyor
        await myReservation.save(myReservationData).then(
          (newReservation) => {
            let data = { ...newReservation.attributes, id: newReservation.id };

            //yeni rezervasyon ekranda kırmızı renkte gözüksün diye veriler yenileniyor
            if (startDate.format('DD-MM-YYYY') === d.format('DD-MM-YYYY')) {
              let newData = areas.filter((area) => area.id !== reservation.id);
              let x = (reservation.x * 1300) / record.planImageUrl.width;
              let y = (reservation.y * 1300) / record.planImageUrl.height;

              let newArea = {
                id: reservation.id,
                reservationId: data.id,
                coordinateId: data.itemId,
                name: reservation.name,
                users: reservation.users,
                shape: 'circle',
                coords: [x, y, 10],
                preFillColor: 'red',
                fillColor: '#0000ff',
              };
              setAreas([...newData, newArea]);
              mapper.current.props.map.areas = [...areas, newArea];
            }
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
          },
          (error) => {
            message.error(error, 2);
          },
        );

        setModal(null);
        setCoords(null);
      }

      setStartDate(moment(date.format('YYYY-MM-DD').toString() + ' 00:00'));
      setEndDate(moment(date.format('YYYY-MM-DD').toString() + ' 23:59'));
    } else message.error('Kullanıcı seçmediniz!', 2);
  };

  //rezervasyon silme
  let deleteCoordinate = async (reservationId) => {
    //rezervasyon bilgileri alınıyor
    let query = new Parse.Query(Reservation);
    query.equalTo('objectId', reservationId);
    let reservation = await query.first();
    let coordinateId = reservation.attributes.itemId;

    //silinecek rezervsyonun coordinat bilgileri bulunuyor
    let queryCooridnate = new Parse.Query(PlanCoordinates);
    queryCooridnate.equalTo('objectId', coordinateId);
    let coordinate = await queryCooridnate.first();
    let coordinateData = { ...coordinate.attributes, id: coordinate.id };

    //rezervasyon siliniyor
    reservation.destroy().then(
      () => {
        //silinen rezervasyonun ekranda gözükmesi için yveriler yenileniyor
        let newData = areas.filter((area) => area.id !== coordinateData.id);
        let x = (coordinateData.x * 1300) / record.planImageUrl.width;
        let y = (coordinateData.y * 1300) / record.planImageUrl.height;
        let newArea = {
          id: coordinateData.id,
          name: coordinateData.name,
          shape: 'circle',
          coords: [x, y, 10],
          preFillColor: 'green',
          fillColor: '#0000ff',
        };
        setAreas([...newData, newArea]);
        mapper.current.props.map.areas = [...areas, newArea];
        message.success('Kayıt silindi', 2);
      },
      (error) => {
        message.error(languagesCms.ERROR_DELETE, 2);
      },
    );
    setCoords(null);
  };

  //tarih seçilme ve tariha göre rezervasyonların getirilmesi
  let onChangeDatePicker = async (date, dateString) => {
    //rezervasyon eklenirken kullanılacak tarihin belirlenmesi
    setDate(moment(dateString + ' 14:00'));
    setStartDate(moment(dateString + ' 00:00'));
    setEndDate(moment(dateString + ' 23:59'));

    //o plandaki koordinatların bulunması ve arealar şekline dönüştürülmesi
    let queryCoordinates = new Parse.Query(PlanCoordinates);
    let coordinates = [];
    queryCoordinates.containedIn('planId', [planId]);
    queryCoordinates.equalTo('isAvailable', true);
    await queryCoordinates
      .find()
      .then(function (results) {
        coordinates = results.map((item, key) => {
          let x = (item.get('x') * 1300) / record.planImageUrl.width;
          let y = (item.get('y') * 1300) / record.planImageUrl.height;
          return {
            id: item.id,
            name: item.get('name'),
            shape: 'circle',
            coords: [x, y, 10],
            preFillColor: 'green',
            fillColor: '#0000ff',
          };
        });
      })
      .catch(function (error) {
        coordinates = [];
      });

    //o plandaki ve tarihteki rezervasyonların bulunması arealar haline dönüştürülmes
    let reservations = [];
    let plandate = dayjs(dateString).format('DD-MM-YYYY').toString();
    let queryReservetions = new Parse.Query(Reservation);
    queryReservetions.containedIn('floorId', [planId]);
    await queryReservetions
      .find()
      .then(function (results) {
        results = results.filter(
          (x) =>
            dayjs(x.get('date')).format('DD-MM-YYYY').toString() === plandate,
        );
        reservations = results.map((item, key) => {
          return {
            id: item.id,
            coordinateId: item.get('itemId'),
            name: '',
            users: item.get('users').id,
            shape: 'circle',
            coords: [item.get('x'), item.get('y'), 10],
            preFillColor: 'red',
            fillColor: '#0000ff',
          };
        });
      })
      .catch(function (error) {
        reservations = [];
      });

    //hangi coordinatta hangi rezervasyon varsa ona göre birleştirme işlemi
    if (coordinates && reservations) {
      setAreas(
        coordinates.map((area) => {
          let reservation = reservations.find(
            (y) => y.coordinateId === area.id,
          );
          if (reservation) {
            area.users = reservation.users;
            area.reservationId = reservation.id;
            area.preFillColor = reservation.preFillColor;
          }
          return area;
        }),
      );
    }
    //ekrana gözükmesi için verileri gönderme işlemi
    mapper.current.props.map.areas = coordinates;
  };

  let onChangeRangePicker = async (date, dateString) => {
    setStartDate(moment(dateString[0] + ' 00:00'));
    setEndDate(moment(dateString[1] + ' 23:59'));
  };

  return (
    <>
      {modal && (
        <div>
          <Modal
            title={''}
            visible={true}
            onOk={() => clickedOk(coords)}
            onCancel={() => setModal(null)}
          >
            <Card title={'Uyarı'} loading={modal ? false : true}>
              <p>{modal.message}</p>
              <p>{modal.users}</p>
            </Card>
          </Modal>
        </div>
      )}
      {coords && (
        <div>
          {/*koordinatlar üzerine tıklanınca açılacak pop up. Koordinat bilgileri ve varsa rezervasyon bilgileri içerir*/}
          <Modal
            title={''}
            visible={true}
            onOk={() => clickOk(coords)}
            onCancel={() => setCoords(null)}
          >
            <Card
              title={
                coords.reservationId ? languagesCms.EDIT : languagesCms.ADD_NEW
              }
              loading={coords ? false : true}
            >
              <Form>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item label="isim">
                      <Input
                        name="name"
                        disabled={true}
                        value={coords.name}
                        onChange={(e) =>
                          setCoords({ ...coords, name: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item label="Tip">
                      <Select
                        defaultValue={coords.type}
                        disabled={true}
                        style={{ width: 250 }}
                        onChange={(v) => {
                          setCoords({ ...coords, type: v });
                        }}
                      >
                        <Select.Option value="public">Havuz Masa</Select.Option>
                        <Select.Option value="admin">Admin Only</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item label={languagesCms.DATE}>
                      {/* <DatePicker disabled={true} defaultValue={() => dayjs(date)} onChange={v => setCoords({ ...coords, date: v })} format='DD/MM/YYYY' showTime={true} /> */}
                      <RangePicker
                        onChange={onChangeRangePicker}
                        defaultValue={[moment(startDate), moment(endDate)]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item label={languagesCms.USERS} required>
                      <ReservationUsersPicker
                        languagesCms={languagesCms}
                        record={coords}
                        setRecord={setCoords}
                        name="users"
                        parseServerURL={parseServerURL}
                        parseAppId={parseAppId}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  {sideRights.lunch && (
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item label="Yemek">
                        <Switch
                          checked={coords.lunch ? true : false}
                          checkedChildren="kullanılacak"
                          unCheckedChildren="kullanılmayacak"
                          onChange={(v) => setCoords({ ...coords, lunch: v })}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {sideRights.service && (
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item label="Servis">
                        <Switch
                          checked={coords.service ? true : false}
                          checkedChildren="kullanılacak"
                          unCheckedChildren="kullanılmayacak"
                          onChange={(v) => setCoords({ ...coords, service: v })}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                {coords.reservationId && (
                  <Popconfirm
                    onConfirm={() => deleteCoordinate(coords.reservationId)}
                    title={languagesCms.CHECK_DELETE}
                    okText={languagesCms.OK_TEXT}
                    cancelText={languagesCms.CANCEL_TEXT}
                  >
                    <Button type="danger" icon={<DeleteOutlined />}>
                      {languagesCms.DELETE}
                    </Button>
                  </Popconfirm>
                )}
              </Form>
            </Card>
          </Modal>
        </div>
      )}
      <div className="grid">
        <div className="presenter">
          <div style={{ position: 'relative' }}>
            <Row direction="row">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <ul>
                  <li>
                    Oturma alanlarına tıklayarak ilgili kişilere rezervasyon
                    işlemini gerçekleştirebilirisiniz.
                  </li>
                  <li>
                    <b>Yeşil</b> alanlar rezervaysona açık alanlardır.
                  </li>
                  <li>
                    <b>Kırmızı</b> alanlar rezerve edilmiş alanlardır.
                  </li>
                </ul>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <p> Rezervsyon tarihini seçiniz:</p>
                <DatePicker
                  onChange={onChangeDatePicker}
                  defaultValue={() => moment(date)}
                />
              </Col>
            </Row>

            {areas && record[name].url && (
              <ImageMapper
                ref={mapper}
                src={record[name].url || ''}
                map={map}
                width={1300}
                height={1300}
                onClick={(area) => clickedArea(area)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
