import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';

const EmailSender = (props) => {
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);

  const { Search } = Input;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = '-createdAt';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let query = [];

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name';
    }
    let restData = await api
      .get(`/rest/emailSender?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.sendGroupEmail) item.sendGroupEmail = <CheckOutlined />;
            else item.sendGroupEmail = <CloseOutlined />;

            if (item.sendEmail) item.sendEmail = <CheckOutlined />;
            else item.sendEmail = <CloseOutlined />;

            if (item.sendNotification)
              item.sendNotification = <CheckOutlined />;
            else item.sendNotification = <CloseOutlined />;

            if (!item.hasOwnProperty('groups')) {
              item.groups = [];
            }

            if (!item.hasOwnProperty('legislations')) {
              item.legislations = [];
            }

            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };

  useEffect(() => {
    get();
  }, [module]);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/emailSender/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/emailSender/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
  };

  let columns = [
    {
      title: languagesCms.TYPE,
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type - b.type,
      sortDirections: ['descend', 'ascend'],
      width: 100,
    },
    {
      title: 'no',
      dataIndex: 'legislations',
      key: 'legislations',
      sorter: (a, b) => a.legislations - b.legislations,
      sortDirections: ['descend', 'ascend'],
      render: (legislations) =>
        legislations.length > 0 &&
        legislations.map((e, i) => (
          <div key={i} style={{ marginBottom: 5 }}>
            {e.no}
          </div>
        )),
      width: 50,
    },
    {
      title: 'header',
      dataIndex: 'legislations',
      key: 'legislations',
      sorter: (a, b) => a.legislations - b.legislations,
      sortDirections: ['descend', 'ascend'],
      render: (legislations) =>
        legislations.length > 0 &&
        legislations.map((e, i) => (
          <div key={i} style={{ marginBottom: 5 }}>
            {e.header}
          </div>
        )),
      width: 50,
    },
    {
      title: 'directorate',
      dataIndex: 'legislations',
      key: 'legislations',
      sorter: (a, b) => a.legislations - b.legislations,
      sortDirections: ['descend', 'ascend'],
      render: (legislations) =>
        legislations.length > 0 &&
        legislations.map((e, i) => (
          <div key={i} style={{ marginBottom: 5 }}>
            {e.directorate}
          </div>
        )),
      width: 50,
    },
    {
      title: 'topic',
      dataIndex: 'legislations',
      key: 'legislations',
      sorter: (a, b) => a.legislations - b.legislations,
      sortDirections: ['descend', 'ascend'],
      render: (legislations) =>
        legislations.length > 0 &&
        legislations.map((e, i) => (
          <div key={i} style={{ marginBottom: 5 }}>
            {e.topic}
          </div>
        )),
      width: 50,
    },
    {
      title: languagesCms.GROUP,
      dataIndex: 'groups',
      key: 'groups',
      sorter: (a, b) => a.groups - b.groups,
      sortDirections: ['descend', 'ascend'],
      render: (groups) =>
        groups.length > 0 &&
        groups.map((e, i) => (
          <div key={i} style={{ marginBottom: 5 }}>
            {e.name}
          </div>
        )),
      width: 50,
    },
    {
      title: 'Yayınlanma Tarihi',
      dataIndex: 'releaseDate',
      key: 'releaseDate',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.releaseDate - b.releaseDate,
      render: (text) => moment(text).format('YYYY-MM-DD'),
      width: 50,
    },
    {
      title: languagesCms.EMAIL,
      dataIndex: 'sendEmail',
      key: 'sendEmail',
      sorter: (a, b) => a.sendEmail - b.sendEmail,
      sortDirections: ['descend', 'ascend'],
      width: 50,
    },
    {
      title: languagesCms.EMAIL + ' (Group)',
      dataIndex: 'sendGroupEmail',
      key: 'sendGroupEmail',
      sorter: (a, b) => a.sendGroupEmail - b.sendGroupEmail,
      sortDirections: ['descend', 'ascend'],
      width: 50,
    },
    {
      title: languagesCms.NOTIFICATION,
      dataIndex: 'sendNotification',
      key: 'sendNotification',
      sorter: (a, b) => a.sendNotification - b.sendNotification,
      sortDirections: ['descend', 'ascend'],
      width: 50,
    },
    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/emailSender/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {isSmall ? '' : languagesCms.DELETE}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}
          <Link to={'emailSender/add'}>
            <Button type="light" icon={<PlusOutlined />} size="large">
              {isSmall ? '' : languagesCms.ADD_NEW}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder="Mail Ara"
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          scroll={{ y: 'calc(100vh - 4em)' }}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default EmailSender;
