import React, { useEffect, useState, useGlobal } from "reactn";
import {Table,Space,Button,Input,Popconfirm} from 'antd';
import moment from 'moment';
import api from '../service/index'
import { Link } from 'react-router-dom';
import qs from 'qs';
import {EditOutlined, DeleteOutlined,PlusOutlined, CheckOutlined,CloseOutlined, Loading3QuartersOutlined} from "@ant-design/icons";

const Meeting = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0)
  let [selectedRows, setSelectedRows] = useState([])
  let [search, setSearch] = useState(false)
  let [loading, setLoading] = useState(false)
  let [modules] = useGlobal("modules");
  let [languagesCms] = useGlobal("languagesCms"); 
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page,filters,sorter);
  }

  let get = async (page, filters, sorter) => {
    if(page) {
      page =  { page : page.current, pageSize: page.pageSize }
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = '-createdAt';

    if (sorter) {
      if(Object.keys(sorter).length) {
          shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
      }
    }

    let _params = {sort: shortString, ...page, };
    if(search.length>2) {
      _params["search"] = search.toLowerCase();
      _params["searchFields"] = "topic";
    }
    let restData = await api.get(`/rest/meetings?${qs.stringify(_params)}`, {_params}).then(({data}) => {
      if(data.result){
        setLoading(false)
        setTotalCount(data.result.total);
        return data.result.rows.map((item,key)=>{
          if (item.isPrivate) 
          item.isPrivate = <CheckOutlined />;
          else 
          item.isPrivate = <CloseOutlined />;
          item.key = key;
          return item;
        })
      }  
    });
    setData(restData);
  }

  useEffect(() => {
      if (modules) {
          setLoading(true)
          get();
      }
  }, [])
 

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
      api.delete(`/rest/meetings/${item_id}`, ({data}) => {});
      let newData = data.filter(el => el._id !== item_id);
      setData(newData);
  }
  let deleteSelectedRows = async () => {
    selectedRows.forEach(item => {
      api.delete(`/rest/meetings/${item._id}`, ({data}) => {});
      let newData = data.filter(el => el._id !== item._id);
      setData(newData);
      get();
    })
  }

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  }

  let columns = [
    {
      title: languagesCms.EXPLANATION,
      dataIndex: 'topic',
      key: 'topic',
      sorter: (a, b) => a.topic - b.topic,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Şifreli',
      dataIndex: 'isPrivate',
      key: 'isPrivate',
      sorter: (a, b) => a.isPrivate - b.isPrivate,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Başlangıç',
      dataIndex: 'startAt',
      key: 'startAt',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.date - b.date,
      render:(text)=>moment(text).utc().format("YYYY-MM-DD HH:mm"),
    },{
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'right',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={"/meetings/edit/"+record._id}>
            <Button icon={<EditOutlined />}>{languagesCms.EDIT}</Button>
          </Link>
           <Popconfirm
            onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
            <Button type="danger" icon={<DeleteOutlined />}>{languagesCms.DELETE}</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div> 
      <div className="list-head">
        <div className="list-title">
           <h1>{module ? module.name : ""}</h1> 
        </div>
        <div className="list-buttons">
          <Button type="danger" icon={<DeleteOutlined />} size = "large" onClick={deleteSelectedRows} style={{ marginRight:"5px" }}>{languagesCms.DELETE_SELECTED}</Button>
          <Link to="/meetings/add">
            <Button type="light" icon={<PlusOutlined />} size = "large">{languagesCms.ADD_NEW}</Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
      <Search placeholder="Açıklama Ara" onChange={onChange} onSearch={(v) =>  {setSearch(v); get()}}  />
        <Table dataSource={data} columns={columns} loading={{ spinning: loading,indicator:<Loading3QuartersOutlined spin /> ,size:"large"}}
        onChange={handleTableChange}
        pagination={{
           total: totalCount
        }}
        rowSelection={{ ...rowSelection }}/>
      </div>
    </div>
  );
};

export default Meeting;
