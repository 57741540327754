import React, { useEffect, useState, useGlobal } from 'reactn';
import { Row, Col, Form, Input, Button, Card, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';

const SurveyWithMediaIcons = (props) => {
  let history = useHistory();
  let id = 'surveyWithMediaIcons';
  let [data, setData] = useState({});
  let [errors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal('languagesCms');
  let [, setPdfIcon] = useGlobal('pdf');
  let [, setSettings] = useGlobal('settings');

  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if (id) {
      await api
        .get('/rest/settings/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  let save = async () => {
    if (id) {
      api
        .put('/rest/settings/' + id, data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
              setSettings(data.result.rows);
              if (data.result.rows) {
                data.result.rows.forEach((element) => {
                  if (element._id === 'surveyWithMediaIcons') {
                    setPdfIcon(element.pdf);
                    localStorage.setItem(
                      'pdfIcon',
                      JSON.stringify(element.pdf),
                    );
                  }
                });
              }
              localStorage.setItem(
                'settings',
                JSON.stringify(data.result.rows),
              );
            });
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/settings');
          } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        });
    } else {
      api
        .post('/rest/settings', data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/settings');
          } else {
            message.error(result_message.message, 2);
          }
        });
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>surveyWithMediaIcons</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading && (
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={'large'} onFinish={save}>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="id"
                    help={errors._id}
                    validateStatus={errors._id ? 'error' : 'success'}
                  >
                    <Input
                      name="_id"
                      disabled={true}
                      value={data._id}
                      onChange={(e) =>
                        setData({ ...data, _id: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="PDF İcon Url"
                    help={errors.pdf}
                    validateStatus={errors.pdf ? 'error' : 'success'}
                  >
                    <Input
                      name="pdf"
                      value={data.pdf}
                      onChange={(e) =>
                        setData({ ...data, pdf: e.target.value })
                      }
                    />
                    <a
                      target={'_blank'}
                      style={{ color: 'red' }}
                      size="sm"
                      href={data.pdf ? data.pdf : null}
                    >
                      {languagesCms.VIEW}
                    </a>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Video İcon Url"
                    help={errors.video}
                    validateStatus={errors.video ? 'error' : 'success'}
                  >
                    <Input
                      name="video"
                      value={data.video}
                      onChange={(e) =>
                        setData({ ...data, video: e.target.value })
                      }
                    />
                    <a
                      target={'_blank'}
                      style={{ color: 'red' }}
                      size="sm"
                      href={data.video ? data.video : null}
                    >
                      {languagesCms.VIEW}
                    </a>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Photo İcon Url"
                    help={errors.photo}
                    validateStatus={errors.photo ? 'error' : 'success'}
                  >
                    <Input
                      name="photo"
                      value={data.photo}
                      onChange={(e) =>
                        setData({ ...data, photo: e.target.value })
                      }
                    />
                    <a
                      target={'_blank'}
                      style={{ color: 'red' }}
                      size="sm"
                      href={data.photo ? data.photo : null}
                    >
                      {languagesCms.VIEW}
                    </a>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Add İcon Url"
                    help={errors.add}
                    validateStatus={errors.add ? 'error' : 'success'}
                  >
                    <Input
                      name="add"
                      value={data.add}
                      onChange={(e) =>
                        setData({ ...data, add: e.target.value })
                      }
                    />
                    <a
                      target={'_blank'}
                      style={{ color: 'red' }}
                      size="sm"
                      href={data.add ? data.add : null}
                    >
                      {languagesCms.VIEW}
                    </a>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block>
                      {' '}
                      {languagesCms.SAVE}{' '}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </div>
    </div>
  );
};

export default SurveyWithMediaIcons;
