import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  Card,
  message,
  InputNumber,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { FileInputSingle, ReservationDepartmentsPicker } from '../components';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import Parse from 'parse';

const PlanDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let placeId = params.placeId !== 'add' ? params.placeId : false;
  let id = params.id !== 'add' ? params.id : false;

  let [customization] = useGlobal('customization');
  let [languagesCms] = useGlobal('languagesCms');

  let parseServerURL = customization.parseServerURL;
  let parseAppId = customization.parseAppId;

  let newRecord = {
    title: null,
    active: false,
    smokingArea: false,
    cafeteria: false,
    order: 0,
    departments: [],
  };

  let [data, setData] = useState(id ? {} : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  Parse.initialize(parseAppId);
  Parse.serverURL = parseServerURL;
  let Plan = Parse.Object.extend('Plan');

   
  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      let query = new Parse.Query(Plan);
      query.get(id).then(
        (plan) => {
          setData(plan.attributes);
          setLoading(false);
        },
        (error) => {
          message.error('Kayıt bilgilerine ulaşılamadı.', 2);
        },
      );
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};
    if (data.title === null || data.title.length === 0)
      errors.title = languagesCms.ENFORCED;
    if (data.planImageUrl === undefined || data.planImageUrl === null)
      errors.planImageUrl = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        let query = new Parse.Query(Plan);
        query.equalTo('objectId', id);
        await query.first().then(
          (object) => {
            object.save(data).then(
              (object) => {
                message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                history.push('/place/' + placeId + '/plan');
              },
              (error) => {
                message.error('Kayıt düzünlenemedi', 2);
              },
            );
          },
          (error) => {
            message.error('Kayıt bilgilerine ulaşılamadı.', 2);
          },
        );
      } else {
        let Place = Parse.Object.extend('Place');
        let Plan = Parse.Object.extend('Plan');
        let query = new Parse.Query(Place);
        await query.get(placeId).then(
          (place) => {
            let plan = new Plan();
            data.placeId = place;
            plan.save(data).then(
              (plan) => {
                message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                history.push('/place/' + placeId + '/plan');
              },
              (error) => {
                message.error(error, 2);
              },
            );
          },
          (error) => {
            message.error('Kayıt bilgilerine ulaşılamadı.', 2);
          },
        );
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''} - Kat (Plan) İşlemleri</h1>
        </div>
        <div className="list-buttons">
          <Link to={'/place/' + placeId + '/plan'}>
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>{' '}
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.TITLE}
                  required
                  help={errors.title}
                  validateStatus={errors.title ? 'error' : 'success'}
                >
                  <Input
                    name="title"
                    value={data.title}
                    onChange={(e) =>
                      setData({ ...data, title: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.STATUS}
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.active ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, active: v })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.ORDER}
                  help={errors.order}
                  validateStatus={errors.order ? 'error' : 'success'}
                >
                  <InputNumber
                    name="order"
                    value={data.order}
                    onChange={(v) => setData({ ...data, order: v })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Sigara Alanı"
                  help={errors.smokingArea}
                  validateStatus={errors.smokingArea ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.smokingArea ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, smokingArea: v })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Kafeterya"
                  help={errors.cafeteria}
                  validateStatus={errors.cafeteria ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.cafeteria ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, cafeteria: v })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="Bölüm">
                  <ReservationDepartmentsPicker
                    record={data}
                    setRecord={setData}
                    name="departments"
                    parseServerURL={parseServerURL}
                    parseAppId={parseAppId}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col>
                <Form.Item
                  label={languagesCms.MEDIA}
                  required
                  help={errors.planImageUrl}
                  validateStatus={errors.planImageUrl ? 'error' : 'success'}
                >
                  <FileInputSingle
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    resize={false}
                    name="planImageUrl"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default PlanDetail;
