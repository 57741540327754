import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Button,
  Switch,
  DatePicker,
  Card,
  message,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import moment from 'moment';

const SessionOrderSubCategoryDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let parentId = params.parentId !== 'add' ? params.parentId : false;
  let id = params.id !== 'add' ? params.id : false;

  let newRecord = {
    categoryId: parentId,
    startDate: new Date(),
    active: '1',
  };

  const [startDate, setStartDate] = useState(null);
  let [data, setData] = useState(id ? {} : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/sessionOrderSubCategory/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/sessionOrderSubCategory/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/sessionOrder/detail/' + parentId);
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/sessionOrderSubCategory', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/sessionOrder/detail/' + parentId);
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };
  function disabledDate(current) {
    return current < moment();
  }
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to={'/sessionOrder/detail/' + parentId}>
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>{' '}
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.START_DATE}
                  required
                  help={errors.startDate}
                  validateStatus={errors.startDate ? 'error' : 'success'}
                >
                  <DatePicker
                    placeholder={data.startDate}
                    defaultValue={() => moment(data.startDate)}
                    showTimeSelect
                    format="DD/MM/YYYY"
                    showTime={true}
                    selected={startDate}
                    startDate={startDate}
                    disabledDate={disabledDate}
                    onChange={(date) => {
                      setData({ ...data, startDate: date });
                      setStartDate(date);
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.STATUS}
                  required
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.active ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, active: v })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default SessionOrderSubCategoryDetail;
