import React, { useEffect, useState, useGlobal } from "reactn";
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import { useParams } from 'react-router-dom'
import api from '../service/index'
import { Link } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';
import { EditOutlined, DeleteOutlined, LeftOutlined, PlusOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

const Info6SubList = (props) => {
  let params = useParams()
  let parent_id = params.id !== "add" ? params.id : false;
  let [modules] = useGlobal("modules");
  let path = props.location.pathname.split('/')[1];
  let [module] = modules.filter(el => el._id === path);
  let [data, setData] = useState([]);
  let [parent, setParent] = useState({});
  let [totalCount, setTotalCount] = useState(0)
  let [selectedRows, setSelectedRows] = useState([])
  let [search, setSearch] = useState(false);
  let [languagesCms] = useGlobal("languagesCms"); 
  const { Search } = Input;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
    parentInfo();
  }

  let parentInfo = async () => {
    if (parent_id) {
      api.get("/rest/informations/" + parent_id).then(({ data: { result, result_message } }) => {
        setParent(result);
      });
    }
  }

  let get = async (page, filters, sorter) => {
    parentInfo();
    if (page) {
      page = { page: page.current, pageSize: page.pageSize }
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'order,-createdAt';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
      }
    }

    let query = [];
    query["module"] =module ? module.refId : "";
    query["parent"] = parent_id;

    let _params = { sort: shortString, ...page, query, module: (module ? module.refId : "") };

    if (search.length > 2) {
      _params["search"] = search.toLowerCase();
      _params["searchFields"] = "name";
    }
    let restData = await api.get(`/rest/informations?${qs.stringify(_params)}`, { _params }).then(({ data }) => {
      if (data) {
        setTotalCount(data.result.total);
        return data.result.rows.map((item, key) => {
          if (item.active)
            item.active = <CheckOutlined />;
          else
            item.active = <CloseOutlined />;
          if (!item.hasOwnProperty('groups')) {
            item.groups = []
          }
          item.key = key;
          return item;
        })
      }
    });
    setData(restData);
  }

  useEffect(() => {
    get();
    parentInfo();
  }, [params.id]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };


  let deleteRow = async (item_id) => {
    api.delete(`/rest/informations/${item_id}`, ({ data }) => { });
    let newData = data.filter(el => el._id !== item_id);
    setData(newData);
  }
  let deleteSelectedRows = async () => {
    selectedRows.forEach(item => {
      api.delete(`/rest/informations/${item._id}`, ({ data }) => { });
      let newData = data.filter(el => el._id !== item._id);
      setData(newData);
      get();
    })
  }


  let columns = [
    {
      title: languagesCms.TITLE,
      dataIndex: 'header',
      key: 'header',
      sorter: (a, b) => a.header - b.header,
      sortDirections: ['descend', 'ascend']
    },
    {
      title:languagesCms.ORDER,
      dataIndex: 'order',
      key: 'order',
      sorter: (a, b) => a.order - b.order,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: languagesCms.ACTIVE,
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: languagesCms.TYPE,
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type - b.type,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: languagesCms.GROUP,
      dataIndex: 'groups',
      key: 'groups',
      sorter: (a, b) => a.groups - b.groups,
      sortDirections: ['descend', 'ascend'],
      render: (user) => user.length > 0 && user.map(e => <div style={{ marginBottom: 5 }}>{e.name}</div>)
    },
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      sorter: (a, b) => a.no - b.no,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: languagesCms.DIRECTORATE,
      dataIndex: 'directorate',
      key: 'directorate',
      sorter: (a, b) => a.directorate - b.directorate,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: languagesCms.TOPIC,
      dataIndex: 'topic',
      key: 'topic',
      sorter: (a, b) => a.topic - b.topic,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: languagesCms.DATE,
      dataIndex: 'date',
      key: 'date',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.date - b.date,
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'right',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small" align="end">
          {(record.type === "Multipager" || record.type === "Multiple") ? (
            <Link to={"/" + (module ? module.refId : "") + "/detail/" + record._id}>
              <Button>
                Detay
              </Button>
            </Link>
          ) : (
            ""
          )}
          <Link to={"/" + (module ? module.refId : "") + "/detail/" + parent_id + "/edit/" + record._id}>
            <Button icon={<EditOutlined />}>{languagesCms.EDIT}</Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
            <Button type="danger" icon={<DeleteOutlined />}>{languagesCms.DELETE}</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
          <h5>{parent ? parent.header : ""}</h5>
        </div>
        <div className="list-buttons">
          <Button type="danger" icon={<DeleteOutlined />} size="large" onClick={deleteSelectedRows} style={{ marginRight: "5px" }}>{languagesCms.DELETE_SELECTED}</Button>
          <Link to={"/" + (module ? module.refId : "") + "/detail/" + parent_id + "/add"}>
            <Button type="light" icon={<PlusOutlined />} size="large" style={{ marginRight: "5px" }}>{languagesCms.ADD_NEW}</Button>
          </Link>
          <Link to={parent.parent === null ? "/" + (module ? module.refId : "") : "/" + (module ? module.refId : "") + "/detail/" + parent.parent}>
            <Button type="light" icon={<LeftOutlined />} size="large" style={{ marginRight: "5px" }} >{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search placeholder={languagesCms.SEARCH} onSearch={(v) => { setSearch(v); get() }} />
        <Table dataSource={data} columns={columns}
          onChange={handleTableChange}
          pagination={{
            total: totalCount
          }}
          rowSelection={{ ...rowSelection }} />
      </div>

    </div>
  );
};



export default Info6SubList;
