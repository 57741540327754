import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  InputNumber,
  Switch,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { LangPicker,MultipleLangPicker } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';

const ShakeWinCategoryDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [languagesCms] = useGlobal('languagesCms');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');

  let newRecord = {
    langs: [{ lang: langCode ? langCode : langCms[0].code, title: '' }],
    name: '',
    order: 0,
    shakeCount: null,
  };
  let [data, setData] = useState(id ? {} : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }
  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/shakeWinCategory/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};
    // if (data.name === null || data.name.length === 0)
    //   errors.name = languagesCms.ENFORCED;

    if (data.shakeCount === null) errors.shakeCount = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/shakeWinCategory/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/shakeWin');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/shakeWinCategory', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/shakeWin');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/shakeWin">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>{' '}
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <MultipleLangPicker
                  langCms={langCms}
                  langCode={langCode}
                  title={'title'}
                  record={data}
                  setRecord={setData}
                  name="lang"
                  languagesCms={languagesCms}
                />
              </Col>
            </Row>
              {/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.TITLE}
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col> */}

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.ORDER}
                  required
                  help={errors.order}
                  validateStatus={errors.order ? 'error' : 'success'}
                >
                  <InputNumber
                    name="order"
                    value={data.order}
                    onChange={(v) => setData({ ...data, order: v })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.STATUS}
                  required
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.active ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, active: v })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.SHAKE_WIN_COUNT}
                  required
                  help={errors.shakeCount}
                  validateStatus={errors.shakeCount ? 'error' : 'success'}
                >
                  <InputNumber
                    name="shakeCount"
                    value={data.shakeCount}
                    onChange={(v) => setData({ ...data, shakeCount: v })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.LANG}>
                  <LangPicker
                    langCms={langCms}
                    langCode={langCode}
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="lang"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ShakeWinCategoryDetail;
