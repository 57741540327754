import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name } = props;
  let [legislations, setLegislations] = useState([]);
  let _params = {
    sort: '-createdAt',
    page: 1,
    pageSize: 100,
    module: 'infos6',
    type: 'PDF',
    legislation: true,
  };
  useEffect(() => {
    api.get(`/rest/informations?${qs.stringify(_params)}`).then(({ data }) => {
      if (data && data.result && data.result.rows.length > 0) {
        let dbLegislations = data.result.rows.sort((a, b) =>
          a.header.localeCompare(b.header),
        );
        setLegislations(dbLegislations);
      }
    });
  }, []);

  return (
    <Select
      value={record[name] || []}
      mode="single"
      placeholder="Mevzuat seçin"
      onChange={(v) => setRecord({ ...record, [name]: v })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {legislations &&
        legislations.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.no ? b.no + ' - ' + b.header : b.header}
          </Select.Option>
        ))}
    </Select>
  );
};
