import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Card, message, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
const { Option } = Select;

const BottomBarDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [data, setData] = useState({});
  let [languagesCms] = useGlobal('languagesCms');
  let [errors] = useState([]);
  let [loading, setLoading] = useState(true);
  const [modules] = useGlobal('modules');

  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if (id) {
      await api
        .get('/rest/bottomBar/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  let save = async () => {
    if (id) {
      api
        .put('/rest/bottomBar/' + id, data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/bottomBar');
          } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        });
    } else {
      api
        .post('/rest/bottomBar', data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/bottomBar');
          } else {
            message.error(result_message.message, 2);
          }
        });
    }
  };

  const moduleName = modules.find((a) => a.refId === data.moduleId);
  useEffect(() => {
    if (moduleName) {
      setData({ ...data, text: moduleName.name });
    }
  }, [moduleName]);

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>BottomBar</h1>
        </div>
        <div className="list-buttons">
          <Link to="/bottomBar">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading && (
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={'large'} onFinish={save}>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label={languagesCms.NAME_MODUL}
                    help={errors.text}
                    validateStatus={errors.text ? 'error' : 'success'}
                  >
                    <h3 style={{ marginTop: '.7rem' }}>
                      {moduleName ? moduleName.name : ''}
                    </h3>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.LANG}
                    help={errors.lang}
                    validateStatus={errors.version ? 'error' : 'success'}
                  >
                    <Input
                      name="lang"
                      value={data.lang}
                      onChange={(e) =>
                        setData({ ...data, lang: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.MODULE}
                    help={errors.type}
                    validateStatus={errors.type ? 'error' : 'success'}
                  >
                    <Select
                      defaultValue={data.moduleId ? data.moduleId : null}
                      style={{
                        width: 120,
                      }}
                      onChange={(e) => setData({ ...data, moduleId: e })}
                    >
                      {params.id.startsWith('News')  && (
                        <>
                          <Option value="infos">{languagesCms.DOCUMENTS}</Option>
                          <Option value="health">{languagesCms.HEALTY_APP}</Option>
                          <Option value="news">{languagesCms.NEWS}</Option>
                          <Option value="postwall">{languagesCms.POSTWALL}</Option>
                        </>
                      )}
                      {params.id.startsWith('Health') && (
                        <>
                          <Option value="infos">{languagesCms.DOCUMENTS}</Option>
                          <Option value="health">{languagesCms.HEALTY_APP}</Option>
                          <Option value="news">{languagesCms.NEWS}</Option>
                          <Option value="postwall">{languagesCms.POSTWALL}</Option>
                        </>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Ios Icon"
                    help={errors.iosIcon}
                    validateStatus={errors.iosIcon ? 'error' : 'success'}
                  >
                    <Input
                      name="iosIcon"
                      value={data.iosIcon}
                      onChange={(e) =>
                        setData({ ...data, iosIcon: e.target.value })
                      }
                    />
                    <a
                      target={'_blank'}
                      style={{ color: 'red' }}
                      size="sm"
                      href={data.iosIcon ? data.iosIcon : null}
                    >
                      {languagesCms.VIEW}
                    </a>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Ios Select Icon"
                    help={errors.iosSelectIcon}
                    validateStatus={errors.iosSelectIcon ? 'error' : 'success'}
                  >
                    <Input
                      name="iosSelectIcon"
                      value={data.iosSelectIcon}
                      onChange={(e) =>
                        setData({ ...data, iosSelectIcon: e.target.value })
                      }
                    />
                    <a
                      target={'_blank'}
                      style={{ color: 'red' }}
                      size="sm"
                      href={data.iosSelectIcon ? data.iosSelectIcon : null}
                    >
                      {languagesCms.VIEW}
                    </a>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Android Icon"
                    help={errors.androidIcon}
                    validateStatus={errors.androidIcon ? 'error' : 'success'}
                  >
                    <Input
                      name="iosIcon"
                      value={data.androidIcon}
                      onChange={(e) =>
                        setData({ ...data, androidIcon: e.target.value })
                      }
                    />
                    <a
                      target={'_blank'}
                      style={{ color: 'red' }}
                      size="sm"
                      href={data.androidIcon ? data.androidIcon : null}
                    >
                      {languagesCms.VIEW}
                    </a>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Android Select Icon"
                    help={errors.androidSelectIcon}
                    validateStatus={
                      errors.androidSelectIcon ? 'error' : 'success'
                    }
                  >
                    <Input
                      name="androidSelectIcon"
                      value={data.androidSelectIcon}
                      onChange={(e) =>
                        setData({ ...data, androidSelectIcon: e.target.value })
                      }
                    />
                    <a
                      target={'_blank'}
                      style={{ color: 'red' }}
                      size="sm"
                      href={
                        data.androidSelectIcon ? data.androidSelectIcon : null
                      }
                    >
                      {languagesCms.VIEW}
                    </a>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Active Color"
                    help={errors.activeColor}
                    validateStatus={errors.activeColor ? 'error' : 'success'}
                  >
                    <Input
                      name="activeColor"
                      value={data.activeColor}
                      onChange={(e) =>
                        setData({ ...data, activeColor: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Passive Color"
                    help={errors.passiveColor}
                    validateStatus={errors.passiveColor ? 'error' : 'success'}
                  >
                    <Input
                      name="passiveColor"
                      value={data.passiveColor}
                      onChange={(e) =>
                        setData({ ...data, passiveColor: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block>
                      {' '}
                      {languagesCms.SAVE}{' '}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </div>
    </div>
  );
};

export default BottomBarDetail;
