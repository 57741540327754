import React from 'react';
import { Switch, Form, Alert } from 'antd';

export default (props) => {
  let { record, setRecord, name } = props;
  let languagesCms = props.languagesCms;
  return (
    <Form.Item>
      {record._id ? (
        record.pin ? (
          <Alert
            type="success"
            message={languagesCms.NOT_PINNED_NOTIFICATION}
            banner
          />
        ) : (
          <Alert
            type="info"
            message={languagesCms.NOT_PINNED_NOTIFICATION}
            banner
          />
        )
      ) : (
        <Alert message={languagesCms.PIN_NOTIFICATION_ALERT1} banner />
      )}

      <Switch
        checked={record[name]}
        disabled={record._id ? true : false}
        checkedChildren={languagesCms.YES}
        unCheckedChildren={languagesCms.NO}
        onChange={(v) => setRecord({ ...record, [name]: v })}
      />
    </Form.Item>
  );
};
