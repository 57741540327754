import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm, message} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import {EditOutlined, DeleteOutlined, PlusOutlined, Loading3QuartersOutlined} from "@ant-design/icons";
import { ImportExcel } from './../components';

const Speaker2 = (props) => {
    const {Search} = Input;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [excelData, setExcelData] = useState([]);
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)
    let [isSmall, ] = useGlobal('isSmall')
    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms"); 
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'order';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page,};
        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "name";
        }
        let restData = await api.get(`/rest/speakers2?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let uploadExcel = async () => {
        let userData;
        let userGroups = [];
    
        let updateCount = 0;
        let insertCount = 0;
    
        if (excelData) {
          for (let i in excelData) {
            userGroups = [];
            if (excelData[i].group_1) {
              userGroups.push(excelData[i].group_1.toString().trim());
            }
            if (excelData[i].group_2) {
              userGroups.push(excelData[i].group_2.toString().trim());
            }
            if (excelData[i].group_3) {
              userGroups.push(excelData[i].group_3.toString().trim());
            }
            if (excelData[i].group_4) {
              userGroups.push(excelData[i].group_4.toString().trim());
            }
            if (excelData[i].group_5) {
              userGroups.push(excelData[i].group_5.toString().trim());
            }
            if (excelData[i].group_6) {
              userGroups.push(excelData[i].group_6.toString().trim());
            }
            if (excelData[i].group_7) {
              userGroups.push(excelData[i].group_7.toString().trim());
            }
            if (excelData[i].group_8) {
              userGroups.push(excelData[i].group_8.toString().trim());
            }
            if (excelData[i].group_9) {
              userGroups.push(excelData[i].group_9.toString().trim());
            }
            if (excelData[i].group_10) {
              userGroups.push(excelData[i].group_10.toString().trim());
            }
            if (excelData[i].group_11) {
              userGroups.push(excelData[i].group_11.toString().trim());
            }
            if (excelData[i].group_12) {
              userGroups.push(excelData[i].group_12.toString().trim());
            }
            if (excelData[i].group_13) {
              userGroups.push(excelData[i].group_13.toString().trim());
            }
            if (excelData[i].group_14) {
              userGroups.push(excelData[i].group_14.toString().trim());
            }
            if (excelData[i].group_15) {
              userGroups.push(excelData[i].group_15.toString().trim());
            }
            if (excelData[i].group_16) {
              userGroups.push(excelData[i].group_16.toString().trim());
            }
            if (excelData[i].group_17) {
              userGroups.push(excelData[i].group_17.toString().trim());
            }
            if (excelData[i].group_18) {
              userGroups.push(excelData[i].group_18.toString().trim());
            }
            if (excelData[i].group_19) {
              userGroups.push(excelData[i].group_19.toString().trim());
            }
            if (excelData[i].group_20) {
              userGroups.push(excelData[i].group_20.toString().trim());
            }

    
            userData = {
              name: excelData[i].name,
              order : excelData[i].order,
              company: excelData[i].company || '',
              position: excelData[i].position || '',
              sessionButtonBackgroundColor: '',
              sessions : [],
              info : "",
              imageurl : "",
              active : true,
              lang : excelData[i].lang || "TR",
              groups: userGroups,
            };
        
    
            await api
                .post('/rest/speakers2', userData)
                .then(({ data: { result, result_message } }) => {});
              insertCount++;
            }
          
          if (updateCount || insertCount) {
            message.error(
              'Excel Yüklendi. ' +
                updateCount +
                ' Güncellendi, ' +
                insertCount +
                ' Kayıt Eklendi.',
              2,
            );
          }
        }
      };
    
      useEffect(() => {
        uploadExcel();
      }, [excelData]);

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };


    let deleteRow = async (item_id) => {
        api.delete(`/rest/speakers2/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/speakers2/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    let columns = [
        {
            title: languagesCms.NAME_LASTNAME,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms.COMPANY,
            dataIndex: 'company',
            key: 'company',
            sorter: (a, b) => a.company - b.company,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.LANG,
            dataIndex: 'lang',
            key: 'lang',
            sorter: (a, b) => a.lang - b.lang,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.ORDER,
            dataIndex: 'order',
            key: 'order',
            sorter: (a, b) => a.order - b.order,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Action',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Link to={"/speakers2/edit/" + record._id}>
                        <Button icon={<EditOutlined/>}>{isSmall ? '' : languagesCms.EDIT}</Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? '' : languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    )}
                    <Link to="/speakers2/add">
                        <Button type="light" icon={<PlusOutlined/>} size="large">{isSmall ? '' : languagesCms.ADD_NEW}</Button>
                    </Link>
                    {!isSmall && <ImportExcel setExcelData={setExcelData} />}
                </div>
            </div>
            <div className="table-wrap">
                <Search placeholder={languagesCms.SEARCH_SPEAKER} onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       onChange={handleTableChange}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>
        </div>
    );
};

export default Speaker2;
