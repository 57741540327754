import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name } = props;
  let [langs, setLangs] = useState([]);
  let languagesCms = props.languagesCms;
  let langCode = props.langCode;
  useEffect(() => {
    record[name] = record[name] || langCode;
    api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
      if (data && data.result && data.result.rows.length > 0) {
        data.result.rows.forEach((element) => {
          if (element._id === 'supportedLangs') {
            setLangs(element.items);
          }
        });
      }
    });
  }, []);

  return (
    <Select
      value={record[name]}
      mode="single"
      placeholder={languagesCms.SELECT_LANGUAGE}
      onChange={(v) => setRecord({ ...record, [name]: v })}
    >
      {langs &&
        langs.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
