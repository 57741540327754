import React, { useEffect, useState, useGlobal } from 'reactn';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  Card,
  message,
  Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';

const PofileSettings = (props) => {
  let history = useHistory();
  let id = 'profileSettings';
  let [data, setData] = useState({});
  let [errors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal('languagesCms');

  useEffect(() => {
    get();
  }, []);


  let get = async () => {
    if (id) {
      await api
        .get('/rest/settings/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  let save = async () => {
    if (id) {
      api
        .put('/rest/settings/' + id, data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            localStorage.setItem('profileSettings', JSON.stringify(result));
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/settings');
          } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        });
    } else {
      api
        .post('/rest/settings', data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            localStorage.setItem('profileSettings', JSON.stringify(result));
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/settings');
          } else {
            message.error(result_message.message, 2);
          }
        });
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Profile Settings</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading && (
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={'large'} onFinish={save}>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="id"
                    help={errors._id}
                    validateStatus={errors._id ? 'error' : 'success'}
                  >
                    <Input
                      name="_id"
                      disabled={true}
                      value={data._id}
                      onChange={(e) =>
                        setData({ ...data, _id: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <br />
                  <small>{languagesCms.PROFILE_SETTINGS_MESSAGE1}</small>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <br />
                  <small>{languagesCms.PROFILE_SETTINGS_MESSAGE2}</small>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <br />
                  <small>{languagesCms.PROFILE_SETTINGS_MESSAGE3}</small>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Row direction="row">
                    <Form.Item
                      label="Show Presidential"
                      help={errors.showPresidential}
                      validateStatus={
                        errors.showPresidential ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showPresidential ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showPresidential: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Show Job Location "
                      help={errors.showJobLocation}
                      validateStatus={
                        errors.showJobLocation ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showJobLocation ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showJobLocation: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Show Groups"
                      help={errors.showGroups}
                      validateStatus={errors.showGroups ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showGroups ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showGroups: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Show Phone"
                      help={errors.showPhone}
                      validateStatus={errors.showPhone ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showPhone ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showPhone: v })}
                      />
                    </Form.Item>
                  </Row>

                  <Row direction="row">
                    <Form.Item
                      label="Show Email"
                      help={errors.showEmail}
                      validateStatus={errors.showEmail ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showEmail ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showEmail: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Show Tag"
                      help={errors.showTag}
                      validateStatus={errors.showTag ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showTag ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showTag: v })}
                      />
                    </Form.Item>
                  </Row>

                  <Row direction="row">
                    <Form.Item
                      label="Show Accounts"
                      help={errors.showAccounts}
                      validateStatus={errors.showAccounts ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showAccounts ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showAccounts: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Show Chat Button"
                      help={errors.showChatButton}
                      validateStatus={
                        errors.showChatButton ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showChatButton ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showChatButton: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Skype"
                      help={errors.editSkype}
                      validateStatus={errors.editSkype ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editSkype ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editSkype: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Linkedin"
                      help={errors.editLinkedin}
                      validateStatus={errors.editLinkedin ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editLinkedin ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editLinkedin: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Twitter"
                      help={errors.editTwitter}
                      validateStatus={errors.editTwitter ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editTwitter ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editTwitter: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Facebook"
                      help={errors.editFacebook}
                      validateStatus={errors.editFacebook ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editFacebook ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editFacebook: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Tag"
                      help={errors.editTag}
                      validateStatus={errors.editTag ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editTag ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editTag: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Birthday String"
                      help={errors.editBirthdayString}
                      validateStatus={
                        errors.editBirthdayString ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.editBirthdayString ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, editBirthdayString: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Tc"
                      help={errors.editTc}
                      validateStatus={errors.editTc ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editTc ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editTc: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Sicil No"
                      help={errors.editRegisterNo}
                      validateStatus={
                        errors.editRegisterNo ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.editRegisterNo ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, editRegisterNo: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Email"
                      help={errors.editEmail}
                      validateStatus={errors.editEmail ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editEmail ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editEmail: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Phone"
                      help={errors.editPhone}
                      validateStatus={errors.editPhone ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editPhone ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editPhone: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Job Phone"
                      help={errors.editJobPhone}
                      validateStatus={errors.editJobPhone ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editJobPhone ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editJobPhone: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Blood Group"
                      help={errors.editBlood}
                      validateStatus={errors.editBlood ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editBlood ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editBlood: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Birthday"
                      help={errors.editBirthday}
                      validateStatus={errors.editBirthday ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editBirthday ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editBirthday: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Groups"
                      help={errors.editGroups}
                      validateStatus={errors.editGroups ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editGroups ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editGroups: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Location"
                      help={errors.editLocation}
                      validateStatus={errors.editLocation ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editLocation ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editLocation: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Job Start Date"
                      help={errors.editJobStartDate}
                      validateStatus={
                        errors.editJobStartDate ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.editJobStartDate ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, editJobStartDate: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Company"
                      help={errors.editCompany}
                      validateStatus={errors.editCompany ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editCompany ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editCompany: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Company Email"
                      help={errors.editCompanyEmail}
                      validateStatus={errors.editCompanyEmail ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editCompanyEmail ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editCompanyEmail: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Department"
                      help={errors.editDepartment}
                      validateStatus={
                        errors.editDepartment ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.editDepartment ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, editDepartment: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Job Location"
                      help={errors.editJobLocation}
                      validateStatus={
                        errors.editJobLocation ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.editJobLocation ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, editJobLocation: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Presidential"
                      help={errors.editPresidential}
                      validateStatus={
                        errors.editPresidential ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.editPresidential ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, editPresidential: v })
                        }
                      />
                    </Form.Item>
                  </Row>

                  <Row direction="row">
                    <Form.Item
                      label="Edit Position"
                      help={errors.editPosition}
                      validateStatus={errors.editPosition ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editPosition ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editPosition: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Edit Name"
                      help={errors.editName}
                      validateStatus={errors.editName ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editName ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editName: v })}
                      />
                    </Form.Item>
                  </Row>

                  <Row direction="row">
                    <Form.Item
                      label="Edit Lastname"
                      help={errors.editLastname}
                      validateStatus={errors.editLastname ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.editLastname ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, editLastname: v })}
                      />
                    </Form.Item>
                  </Row>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Row direction="row">
                    <Form.Item
                      label="Require Instagram"
                      help={errors.requireInstagram}
                      validateStatus={
                        errors.requireInstagram ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireInstagram ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireInstagram: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Skype"
                      help={errors.requireSkype}
                      validateStatus={errors.requireSkype ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.requireSkype ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, requireSkype: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Linkedin"
                      help={errors.requireLinkedin}
                      validateStatus={
                        errors.requireLinkedin ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireLinkedin ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireLinkedin: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Twitter"
                      help={errors.requireTwitter}
                      validateStatus={
                        errors.requireTwitter ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireTwitter ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireTwitter: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Facebook"
                      help={errors.requireFacebook}
                      validateStatus={
                        errors.requireFacebook ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireFacebook ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireFacebook: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Tag"
                      help={errors.requireTag}
                      validateStatus={errors.requireTag ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.requireTag ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, requireTag: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Birthday String"
                      help={errors.requireBirthdayString}
                      validateStatus={
                        errors.requireBirthdayString ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireBirthdayString ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireBirthdayString: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Tc"
                      help={errors.requireTc}
                      validateStatus={errors.requireTc ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.requireTc ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, requireTc: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Sicil No"
                      help={errors.requireRegisterNo}
                      validateStatus={
                        errors.requireRegisterNo ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireRegisterNo ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireRegisterNo: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Email"
                      help={errors.requireEmail}
                      validateStatus={errors.requireEmail ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.requireEmail ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, requireEmail: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Phone"
                      help={errors.requirePhone}
                      validateStatus={errors.requirePhone ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.requirePhone ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, requirePhone: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Job Phone"
                      help={errors.requireJobPhone}
                      validateStatus={
                        errors.requireJobPhone ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireJobPhone ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireJobPhone: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Blood Group"
                      help={errors.requireBlood}
                      validateStatus={errors.requireBlood ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.requireBlood ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, requireBlood: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Birthday"
                      help={errors.requireBirthday}
                      validateStatus={
                        errors.requireBirthday ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireBirthday ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireBirthday: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Groups"
                      help={errors.requireGroups}
                      validateStatus={
                        errors.requireGroups ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireGroups ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, requireGroups: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Location"
                      help={errors.requireLocation}
                      validateStatus={
                        errors.requireLocation ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireLocation ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireLocation: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Job Start Date"
                      help={errors.requireJobStartDate}
                      validateStatus={
                        errors.requireJobStartDate ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireJobStartDate ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireJobStartDate: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Company"
                      help={errors.requireCompany}
                      validateStatus={
                        errors.requireCompany ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireCompany ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireCompany: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Company Email"
                      help={errors.requireCompanyEmail}
                      validateStatus={
                        errors.requireCompanyEmail ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireCompanyEmail ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireCompanyEmail: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Department"
                      help={errors.requireDepartment}
                      validateStatus={
                        errors.requireDepartment ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireDepartment ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireDepartment: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Job Location"
                      help={errors.requireJobLocation}
                      validateStatus={
                        errors.requireJobLocation ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireJobLocation ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireJobLocation: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Presidential"
                      help={errors.requirePresidential}
                      validateStatus={
                        errors.requirePresidential ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requirePresidential ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requirePresidential: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Position"
                      help={errors.requirePosition}
                      validateStatus={
                        errors.requirePosition ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requirePosition ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requirePosition: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Lastname"
                      help={errors.requireLastname}
                      validateStatus={
                        errors.requireLastname ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.requireLastname ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, requireLastname: v })
                        }
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Name"
                      help={errors.requireName}
                      validateStatus={errors.requireName ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.requireName ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, requireName: v })}
                      />
                    </Form.Item>
                  </Row>
                  <Row direction="row">
                    <Form.Item
                      label="Require Company Email"
                      help={errors.requireCompanyEmail}
                      validateStatus={errors.requireCompanyEmail ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.requireCompanyEmail ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, requireCompanyEmail: v })}
                      />
                    </Form.Item>
                  </Row>
                </Col>
              </Row>

              <Typography
                style={{
                  textAlign: 'center',
                  marginTop: '0.75rem',
                  marginBottom: '0.75rem',
                }}
              >
                Aşağıdaki Seçenekler Sonunda Dev Yazan Cms'i Sonunda Dev Olmayan
                uygulama' yı etkiler.
              </Typography>

              <Row direction="row">
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Department"
                      help={errors.showDepartment}
                      validateStatus={
                        errors.showDepartment ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showDepartment ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showDepartment: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Department Dev"
                      help={errors.showDepartmentDev}
                      validateStatus={
                        errors.showDepartmentDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showDepartmentDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showDepartmentDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Position"
                      help={errors.showPosition}
                      validateStatus={errors.showPosition ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showPosition ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showPosition: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Position Dev"
                      help={errors.showPositionDev}
                      validateStatus={
                        errors.showPositionDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showPositionDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showPositionDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Company"
                      help={errors.showCompany}
                      validateStatus={errors.showCompany ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showCompany ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showCompany: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Company Dev"
                      help={errors.showCompanyDev}
                      validateStatus={
                        errors.showCompanyDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showCompanyDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showCompanyDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Job Start Date"
                      help={errors.showJobStartDate}
                      validateStatus={
                        errors.showJobStartDate ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showJobStartDate ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showJobStartDate: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Job Start Date Dev"
                      help={errors.showJobStartDateDev}
                      validateStatus={
                        errors.showJobStartDateDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showJobStartDateDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showJobStartDateDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show TC"
                      help={errors.showTc}
                      validateStatus={errors.showTc ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showTc ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showTc: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show TC Dev"
                      help={errors.showTcDev}
                      validateStatus={errors.showTcDev ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showTcDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showTcDev: v })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Job Phone"
                      help={errors.showJobPhone}
                      validateStatus={errors.showJobPhone ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showJobPhone ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showJobPhone: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Job Phone Dev"
                      help={errors.showJobPhoneDev}
                      validateStatus={
                        errors.showJobPhoneDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showJobPhoneDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showJobPhoneDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Location"
                      help={errors.showLocation}
                      validateStatus={errors.showLocation ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showLocation ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showLocation: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Location Dev"
                      help={errors.showLocationDev}
                      validateStatus={
                        errors.showLocationDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showLocationDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showLocationDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Birthday"
                      help={errors.showBirthday}
                      validateStatus={errors.showBirthday ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showBirthday ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showBirthday: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Birthday Dev"
                      help={errors.showBirthdayDev}
                      validateStatus={
                        errors.showBirthdayDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showBirthdayDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showBirthdayDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Blood Group"
                      help={errors.showBlood}
                      validateStatus={errors.showBlood ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showBlood ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showBlood: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Blood Group Dev"
                      help={errors.showBloodDev}
                      validateStatus={errors.showBloodDev ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showBloodDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showBloodDev: v })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Sicil No"
                      help={errors.showRegisterNo}
                      validateStatus={
                        errors.showRegisterNo ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showRegisterNo ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showRegisterNo: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Sicil No Dev"
                      help={errors.showRegisterNoDev}
                      validateStatus={
                        errors.showRegisterNoDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showRegisterNoDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showRegisterNoDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Birthday String"
                      help={errors.showBirthdayString}
                      validateStatus={
                        errors.showBirthdayString ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showBirthdayString ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showBirthdayString: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Birthday String Dev"
                      help={errors.showBirthdayStringDev}
                      validateStatus={
                        errors.showBirthdayStringDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showBirthdayStringDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showBirthdayStringDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Facebook"
                      help={errors.showFacebook}
                      validateStatus={errors.showFacebook ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showFacebook ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showFacebook: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Facebook Dev"
                      help={errors.showFacebookDev}
                      validateStatus={
                        errors.showFacebookDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showFacebookDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showFacebookDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Twitter"
                      help={errors.showTwitter}
                      validateStatus={errors.showTwitter ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showTwitter ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showTwitter: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Twitter Dev"
                      help={errors.showTwitterDev}
                      validateStatus={
                        errors.showTwitterDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showTwitterDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showTwitterDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Instagram"
                      help={errors.showInstagram}
                      validateStatus={
                        errors.showInstagram ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showInstagram ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showInstagram: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Instagram Dev"
                      help={errors.showInstagramDev}
                      validateStatus={
                        errors.showInstagramDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showInstagramDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showInstagramDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Linkedin"
                      help={errors.showLinkedin}
                      validateStatus={errors.showLinkedin ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showLinkedin ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showLinkedin: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Linkedin Dev"
                      help={errors.showLinkedinDev}
                      validateStatus={
                        errors.showLinkedinDev ? 'error' : 'success'
                      }
                    >
                      <Switch
                        checked={data.showLinkedinDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          setData({ ...data, showLinkedinDev: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Skype"
                      help={errors.showSkype}
                      validateStatus={errors.showSkype ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showSkype ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showSkype: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Skype Dev"
                      help={errors.showSkypeDev}
                      validateStatus={errors.showSkypeDev ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showSkypeDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showSkypeDev: v })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Name"
                      help={errors.showName}
                      validateStatus={errors.showName ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showName ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showName: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Name Dev"
                      help={errors.showNameDev}
                      validateStatus={errors.showNameDev ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showNameDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showNameDev: v })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show LastName"
                      help={errors.showLastName}
                      validateStatus={errors.showLastName ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showLastName ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showLastName: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show LastName Dev"
                      help={errors.showLastNameDev}
                      validateStatus={errors.showLastNameDev ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showLastNameDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showLastNameDev: v })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show BirthDate Status"
                      help={errors.showBirthDate}
                      validateStatus={errors.showBirthDate ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showBirthDate ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showBirthDate: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show BirthDate Status Dev"
                      help={errors.showBirthDateDev}
                      validateStatus={errors.showBirthDateDev ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showBirthDateDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showBirthDateDev: v })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Live Support Admin Status"
                      help={errors.showLiveSupportAdmin}
                      validateStatus={errors.showLiveSupportAdmin ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showLiveSupportAdmin ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showLiveSupportAdmin: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Live Support Admin Dev"
                      help={errors.showLiveSupportAdminDev}
                      validateStatus={errors.showLiveSupportAdminDev ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showLiveSupportAdminDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showLiveSupportAdminDev: v })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Company Email"
                      help={errors.showCompanyEmail}
                      validateStatus={errors.showCompanyEmail ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showCompanyEmail ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showCompanyEmail: v })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 10 }}>
                    <Form.Item
                      label="Show Company Email Dev"
                      help={errors.showCompanyEmailDev}
                      validateStatus={errors.showCompanyEmailDev ? 'error' : 'success'}
                    >
                      <Switch
                        checked={data.showCompanyEmailDev ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) => setData({ ...data, showCompanyEmailDev: v })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Row>
              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block>
                      {' '}
                      {languagesCms.SAVE}{' '}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </div>
    </div>
  );
};

export default PofileSettings;
