import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm} from 'antd';
import moment from 'moment';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';

import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    Loading3QuartersOutlined,
    LikeOutlined,
    CommentOutlined
} from "@ant-design/icons";

const Storie = (props) => {

    const {Search} = Input;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)
    let [isSmall, ] = useGlobal('isSmall')

    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms"); 
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'order,-startDate';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page,};


        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "title";
        }
        let restData = await api.get(`/rest/stories?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    if (item.active)
                        item.active = <CheckOutlined/>;
                    else
                        item.active = <CloseOutlined/>;
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };


    let deleteRow = async (item_id) => {
        api.delete(`/rest/stories/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/stories/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }
    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    let columns = [
        {
            title: languagesCms.SHARE,
            dataIndex: 'userId',
            key: 'userId',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend'],
            render: (user) => ( ( user && [user.name, user.lastname].filter(x => x).join(' ') ) || "Silinen Kullanıcı" )
        },
        {
            title: languagesCms.MEDIA,
            dataIndex: 'medias',
            key: 'medias',
            render: (text, record) => {
                return (
                    <div style={{width: isSmall ? '50px' : '100px'}}>
                        {record.items.length > 0 &&
                            <div>
                                {record.items[0].type === "image" &&
                                    <img src={record.items[0].url} style={{width: '100%'}} alt=""/>
                                }
                                {record.items[0].type === "video" &&
                                    <img src={record.items[0].thumb} style={{width: '100%'}} alt=""/>
                                }
                            </div>
                        }
                    </div>
                )
            }
        },
        {
            title: languagesCms.TITLE,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms.ACTIVE,
            dataIndex: 'active',
            key: 'active',
            sorter: (a, b) => a.active - b.active,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.START_DATE,
            dataIndex: 'startDate',
            key: 'startDate',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.startDate - b.startDate,
            render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
        },
        {
            title:languagesCms.END_DATE,
            dataIndex: 'endDate',
            key: 'endDate',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.endDate - b.endDate,
            render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
        },
        {
            title: 'Action',
            key: 'action',
            align: 'right',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <div style={{margin: 'auto'}}>
                        <Link to={"/stories/likes/" + record._id}>
                                <Button style={{marginBottom:"5px",display: "block"}} size="small" icon={<LikeOutlined />}>
                                    {!isSmall && languagesCms.LIKES}
                                </Button>
                        </Link>
                        <Link to={"/stories/comments/" + record._id}>
                            <Button size="small" style={{display: "block"}} icon={<CommentOutlined/>}>
                                {isSmall ? '' : languagesCms.COMMENTS}
                            </Button>
                        </Link>
                    </div>
                    {(( record && record.userId && record.userId.role && record.userId.role === "admin") || ( record && record.userId && record.userId.role && record.userId.role === "superAdmin"))  &&
                        <Link to={"/stories/" + record.refId + "/add"}>
                            <Button icon={<PlusOutlined/>}>
                                {!isSmall && languagesCms.ADD_NEW}
                            </Button>
                        </Link>
                    }
                    <Link to={"/stories/edit/" + record._id}>
                        <Button icon={<EditOutlined/>}>
                            {!isSmall && languagesCms.EDIT}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {!isSmall && languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    {!isSmall &&
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    }
                    <Link to="/stories/add">
                        <Button type="light" icon={<PlusOutlined/>} size={'large'}>{!isSmall && languagesCms.ADD_NEW}</Button>
                    </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder={languagesCms.SEARCH_STORIE} onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange} scroll={{x: 'auto'}}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={!isSmall && {...rowSelection}}/>
            </div>

        </div>
    );
};


export default Storie;
