import React, { useState, useEffect } from 'react';
import { Select, Input, Button, Form } from 'antd';
import api from '../service/index';
import qs from 'qs';
import { PlusOutlined, LineOutlined } from '@ant-design/icons';

export default (props) => {
  let { record, setRecord } = props;
  let [companyLangs, setCompanyLangs] = useState([]);

  let languagesCms = props.languagesCms;
  let langCode = props.langCode;
  let setLang = (name, value, index) => {
    setRecord({
      ...record,
      langs: record.langs.map((l, i) => {
        l.lang = i === index && name === 'lang' ? value : l.lang;
        l.title = i === index && name === 'title' ? value : l.title;
        return { ...l };
      }),
    });
  };

  useEffect(() => {
    record.langs[0].lang = record.langs[0].lang || langCode;
    api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
      if (data && data.result && data.result.rows.length > 0) {
        data.result.rows.forEach((element) => {
          if (element._id === 'supportedLangs') {
            setCompanyLangs(element.items);
          }
        });
      }
    });
  }, []);
  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        {companyLangs.length > record.langs.length && (
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={() => {
              setRecord({
                ...record,
                langs: [...record.langs, { lang: '', title: '' }],
              });
            }}
          >
            {languagesCms.ADD_LANG}
          </Button>
        )}
      </div>
      <div>
        {record.langs.length > 0 &&
          record.langs.map((l, i) => (
            <div key={i} style={{ display: 'flex' }}>
              <div style={{ marginTop: i > 0 ? 20 : 0 }}>
                <Form.Item label={languagesCms.LANG}>
                  <Select
                    style={{ width: 400, marginRight: 5 }}
                    value={l.lang || []}
                    mode="single"
                    placeholder={languagesCms.SELECT_LANGUAGE}
                    onChange={(v) => setLang('lang', v, i)}
                  >
                    {companyLangs &&
                      companyLangs.map((b) => (
                        <Select.Option
                          disabled={
                            record.langs.find((l) => l.lang === b._id)
                              ? true
                              : false
                          }
                          key={b._id}
                          value={b._id}
                        >
                          {b.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item label={`${l.lang || ''} ${languagesCms.TITLE}`}>
                  <Input
                    style={{ width: 400 }}
                    placeholder={languagesCms.TITLE}
                    name={l.title}
                    value={l.title}
                    onChange={(e) => setLang('title', e.target.value, i)}
                  />
                </Form.Item>
              </div>

              {record.langs.length > 1 && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 20,
                  }}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<LineOutlined />}
                    onClick={() => {
                      setRecord({
                        ...record,
                        langs: record.langs.filter((r, ir) => i !== ir),
                      });
                    }}
                  />
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
