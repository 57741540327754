import React, { useState, useEffect } from 'react';
import { Upload, message, Progress, Button } from 'antd';
import { PlusOutlined, LoadingOutlined, FilePdfOutlined, DeleteOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import api from '../service/index'

export default (props) => {
	let { record, setRecord, name, disabled, resize } = props;
	let [, setFile] = useState([]);
	let [imageUrl, setImageUrl] = useState(false);
	let [pdfUrl, setPdfUrl] = useState(false);
	let [loading, setLoading] = useState(false)
	let [uploadStart, setUploadStart] = useState(false)
	let languagesCms = props.languagesCms;
	const [progress, setProgress] = useState(0);
	let [_download, set_Download] = useState(false)

	useEffect(() => {
		if(record[name][0]) {
			setImageUrl(record[name][0].thumb?record[name][0].thumb:record[name][0].url);
			set_Download(record[name][0])
			setPdfUrl(record[name][0].url);
		}
	}, []);
	let handleChange = info => {
		if (!info.event && uploadStart === false) {
			setUploadStart(true)
			const formData = new FormData();

			formData.append('files_0', info.file.originFileObj)
			if (info.file.originFileObj.size > 56844918)
			message.warning(languagesCms.WARNING_FILE_SIZE)
		    else {
			formData.append("resize",resize)
			api.post("/api/upload", formData, 
			{ headers: { 'ContenType': 'multipart/form-data'},
			onUploadProgress: event => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				setProgress(percent-1);
			} })
			.then(({ data: { result, result_message } }) => {
				set_Download(result[0] ? result[0] : null)
				setTimeout(() => setProgress(100), 1000);
				setTimeout(() => setProgress(0), 2000);
				if (result_message.type === 'success') {
					setPdfUrl(result[0].type==="document"?result[0].url:null);
					if (result[0].duration) {
						setTimeout(()=>{
							setImageUrl(result[0].type==="video"?result[0].thumb:result[0].url);
						},1000)
					}
					else setImageUrl(result[0]?result[0].thumb:result[0].url);
					// delete result[0].width
					// delete result[0].height	
					setFile(result[0]);
					setRecord({...record, [name]:result[0]})
					setUploadStart(false)
				}
				setLoading(false);
			});
		}
		}
	};

	let beforeUpload = () => {
		setImageUrl(false)
		setPdfUrl(false)
		setLoading(true);
	}
	let remove = () => {
		setFile([]);
		setImageUrl(false);
		setPdfUrl(false);
		setRecord({ ...record, [name]: null });
	};
	
	let downloadImage = (imageUrl) => {
		imageUrl = _download.url ? _download.url : record.media.url
		if (!imageUrl.length) return;
		fetch(imageUrl, {
		  method: "GET",
		  headers: {},
		})
		  .then((response) => {
			  let extension = imageUrl.split(".").reverse()[0]
			response.arrayBuffer().then(function (buffer) {
			  const url = window.URL.createObjectURL(new Blob([buffer]));
			  const link = document.createElement("a");
			  link.href = url;
			  link.setAttribute(
				"download",
				`image.${extension}`
			  );
			  document.body.appendChild(link);
			  link.click();
			});
		  })
		  .catch((error) => {
			message.warning("Dosya İndirilmesine İzin Verilmiyor")
		  });
	  };

	const uploadButton = (
		<div icon={loading ? <LoadingOutlined onClick={() => handleChange()} /> : <PlusOutlined />}>
			<PlusOutlined />{' Upload'}
		</div>
	);

	if (name === "pdf") {
		return <>
			<Upload
				name={name}
				listType="picture-card"
				className="avatar-uploader"
				showUploadList={false}
				beforeUpload={beforeUpload}
				onChange={handleChange}
				disabled={disabled}
			>
        {pdfUrl ? <FilePdfOutlined style={{ fontSize: '50px' }} /> :  uploadButton}
      </Upload>
	  {pdfUrl ?<div>
		 <div>
					<Button type="danger" icon={<DeleteOutlined />} size="medium" onClick={() => remove()} style={{ marginRight: "3px" }}></Button>
					<a target='_blank' rel="noopener noreferrer" href={pdfUrl}><Button icon={<EyeOutlined  />} style={{ marginRight: "3px" }} size="medium"  type="primary"></Button></a>
					<Button type="primary" icon={<DownloadOutlined />} onClick={() => downloadImage()} size="medium" style={{ margin: "auto" }}/>
		 </div>
		 </div>: null}
	  {progress > 0 ? <Progress style={{width:'60%'}} percent={progress} /> : null}
	</>
	}
	else {
		return <>
			<Upload
				name={name}
				listType="picture-card"
				className="avatar-uploader"
				showUploadList={false}
				beforeUpload={beforeUpload}
				onChange={handleChange}
				disabled={disabled}
			>
				{imageUrl ? <div>
					<img src={imageUrl} alt={name} style={{ width: '100%', height: 'inherit', objectFit: 'contain' }} />
				</div> : uploadButton}
			</Upload>
			{imageUrl ? <div>
				<div>
					<Button type="danger" icon={<DeleteOutlined />} size="medium" onClick={() => remove()} style={{ marginRight: "3px" }}></Button>
					<a target='_blank' rel="noopener noreferrer" href={imageUrl}><Button icon={<EyeOutlined  />} size="medium" style={{ marginRight: "3px" }} type="primary"></Button></a>
					<Button type="primary" icon={<DownloadOutlined />} onClick={() => downloadImage()} size="medium" style={{ margin: "auto" }}/>
		 </div>
		 </div>: null}
	  {progress > 0 ? <Progress style={{width:'60%'}} percent={progress} /> : null}
	</>
	}
};
