import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name } = props;
  let [modules, setModules] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 1000 };
  useEffect(() => {
    api.get(`/rest/modules?${qs.stringify(_params)}`).then(({ data }) => {
      if (data && data.result && data.result.rows.length > 0) {
        let dbModules = data.result.rows.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        setModules(dbModules);
      }
    });
  }, []);

  return (
    <Select
      value={record[name] || []}
      mode="multiple"
      placeholder="Modül seçin"
      onChange={(v) => setRecord({ ...record, [name]: v })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {modules &&
        modules.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
