import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name } = props;
  let [departments, setDepartments] = useState([]);
  let _params = { page: 1, pageSize: 100, sort: 'order' };
  useEffect(() => {
    api
      .get(`/rest/reservationDepartments?${qs.stringify(_params)}`)
      .then(({ data }) => {
        if (data && data.result && data.result.rows.length > 0) {
          let dbDepartments = data.result.rows;
          setDepartments(dbDepartments);
        }
      });
  }, []);

  return (
    <Select
      value={record[name]}
      mode="multiple"
      placeholder="Bölüm seçiniz"
      onChange={(v) => setRecord({ ...record, [name]: v })}
    >
      {departments &&
        departments.map((b) => (
          <Select.Option key={b.name} value={b.name}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
