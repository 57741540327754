import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, message} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import {useParams} from 'react-router-dom'

import {
    LeftOutlined,
    PlusOutlined,
    MinusOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const EventAttendeeList = (props) => {

    let params = useParams()
    let id = params.subid;
    const dayjs = require('dayjs');
    const {Search} = Input;
    let [data, setData] = useState([]);
    let [dataEvent, setDataEvent] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false);
    let [isSmall, ] = useGlobal('isSmall');
    let [languagesCms] = useGlobal("languagesCms"); 

    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let [module] = modules.filter(el => el._id === path);

    let query = []
    query["deleted"] = false;
    query["allow_donation"] = false;
    query["blood_donation"] = false;
    query["platelet_donation"] = false;
    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'name';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page, query};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "name, email, phone";
        }
        let eventData = await api.get("/rest/events/" + id);
        console.log(eventData  )
        setDataEvent(eventData.data.result)
        let restData = await api.get(`/rest/attendees?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    let attendee = eventData.data.result.joins.find(e => e._id === item._id)
                    let attendees = eventData.data.result.joins.find(e => e.id === item._id)
                    if (attendee || attendees) {
                        item.isEventData = true;
                    } else {
                        item.isEventData = false;
                    }
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }
    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    let eventUpdate = async (e) => {
        let json = {
            id: e._id,
            date: dayjs().toDate()
        }
        if (e.isEventData === true)
            dataEvent.joins = dataEvent.joins.filter(g => g.id !== e._id)
        else
            dataEvent.joins.push(json)

        api.put("/rest/events/" + dataEvent._id, dataEvent).then(({data: {result, result_message}}) => {
            if (result_message.type === 'success') {
                message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                get();
            } else
                message.error(result_message.message, 2);
        })
    }

    let columns = [
        {
      title: languagesCms.EMAIL,
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email - b.email,
            sortDirections: ['descend', 'ascend']
        }, {
      title: languagesCms.PHONE,
            dataIndex: 'phone',
            key: 'phone',
            sorter: (a, b) => a.phone - b.phone,
            sortDirections: ['descend', 'ascend']
        }, {
      title: languagesCms.NAME,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend']
        }, {
      title: languagesCms.LASTNAME,
            dataIndex: 'lastname',
            key: 'lastname',
            sorter: (a, b) => a.lastname - b.lastname,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Action',
            dataIndex: 'attendees',
            key: 'action',
            render: (attendees, record) => {
                return (
                    <Space size="small">
                        <Button icon={record.isEventData ? <MinusOutlined/> : <PlusOutlined/>}
                                onClick={() => eventUpdate(record)}>
                        </Button>
                    </Space>
                )
            }
        }
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""} </h1>
                    <p><font face="tahoma" size="3">{data ? data.name : ""} </font></p>
                </div>
                <div className="list-buttons">
                    <div style={{marginRight: "5px"}}>
                    </div>
                    <Link to={"/event/detail/" + dataEvent._id}>
                        <Button type="light" icon={<LeftOutlined/>} size={'large'}>{!isSmall && languagesCms.BACK}</Button>
                    </Link>

                </div>
            </div>
            <div className="table-wrap">
                <Search placeholder={languagesCms.SEARCH_NAME_LASTNAME_PHONE} onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}/>
            </div>

        </div>
    );
};


export default EventAttendeeList;
