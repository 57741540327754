import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Select,
  DatePicker,
  Switch,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import {
  MapInput,
  CoordinateTypesPicker,
  AttendeePicker2,
  AttendeePicker3,
  DbSelectAttendee,
  DbSelectPlan,
  GroupPicker,
} from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import moment from 'moment';

const { Option } = Select;

const RoomMateDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let newRecord = {
    sender_id: '',
    reciver_id: '',
    other_id: '',
    sender: {
      name: '',
      lastname: '',
    },
    reciver: {
      name: '',
      lastname: '',
    },
    other: {
      name: '',
      lastname: '',
    },
    status: 'wait',
    roomType: '2',
  };

  let [data, setData] = useState(newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let [modules] = useGlobal('modules');
  let [languagesCms] = useGlobal('languagesCms');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/roommate/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};
    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        console.log('post kısmıııı-------------------/rest/roommate/submit/', data);
        api
          .put('/rest/roommate/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/roommate');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/roommate', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/roommate');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
      if (id && data.other && data.other._id) {
        console.log('+++++++++++++++++++++++++++++++++++++', data);
        api
          .put('/rest/roommate/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_ADD_RECORD, 2);
              history.push('/roommate');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      }
    }
  };

  // console.log('data', data);

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/roommate">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col span={24}>
                <Form.Item
                  label="Oda Tipi Seçiniz"
                  required
                  help={errors.type}
                  alidateStatus={errors.type ? 'error' : 'success'}
                >
                  <Switch
                    checkedChildren={'2 Kişilik Oda'}
                    unCheckedChildren={'3 Kişilik Oda'}
                    initialChecked={data.roomType === '2' ? '2' : '3'}
                    record={data}
                    setRecord={setData}
                    name="roomType"
                    checked={data.roomType === '2' ? true : false}
                    onChange={(e) => {
                      setData({
                        ...data,
                        roomType: e ? '2' : '3',
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {data.roomType === '2' && (
              <>
                <Form.Item
                  label="1. Oda Arkadaşı"
                  required
                  help={errors.sender}
                  alidateStatus={errors.sender ? 'error' : 'success'}
                >
                  <AttendeePicker2
                    record={data}
                    setRecord={setData}
                    name="sender"
                    languagesCms={languagesCms}
                  />
                </Form.Item>
                <Form.Item
                  label="2. Oda Arkadaşı"
                  required
                  help={errors.reciver}
                  alidateStatus={errors.reciver ? 'error' : 'success'}
                >
                  <AttendeePicker2
                    record={data}
                    setRecord={setData}
                    name="reciver"
                    languagesCms={languagesCms}
                  />
                </Form.Item>
                {/* <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label="1. Oda Arkadaşı"
                      help={errors.sender}
                      validateStatus={errors.sender ? 'error' : 'success'}
                    >
                      <AttendeePicker2
                        record={data}
                        setRecord={setData}
                        name="sender"
                        languagesCms={languagesCms}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label="2. Oda Arkadaşı"
                      help={errors.reciver}
                      validateStatus={errors.reciver ? 'error' : 'success'}
                    >
                      <AttendeePicker2
                        record={data}
                        setRecord={setData}
                        name="reciver"
                        languagesCms={languagesCms}
                      />
                    </Form.Item>
                  </Col>
                </Row> */}
              </>
            )}
            {/* {!data.roomType && (
              <>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="1. Oda Arkadaşı"
                    help={errors.sender}
                    validateStatus={errors.sender ? 'error' : 'success'}
                  >
                    <AttendeePicker3
                      record={data}
                      setRecord={setData}
                      name="sender"
                      languagesCms={languagesCms}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="2. Oda Arkadaşı"
                    help={errors.reciver}
                    validateStatus={errors.reciver ? 'error' : 'success'}
                  >
                    <AttendeePicker3
                      record={data}
                      setRecord={setData}
                      name="reciver"
                      languagesCms={languagesCms}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="3. Oda Arkadaşı"
                    help={errors.other}
                    validateStatus={errors.other ? 'error' : 'success'}
                  >
                    <AttendeePicker3
                      record={data}
                      setRecord={setData}
                      name="other"
                      languagesCms={languagesCms}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
            )} */}
            {data.roomType === '3' && (
              <>
                {console.log('3 kişilik oda', data.roomType, data)}
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label="1. Oda Arkadaşı"
                      help={errors.sender}
                      validateStatus={errors.sender ? 'error' : 'success'}
                    >
                      <AttendeePicker3
                        record={data}
                        setRecord={setData}
                        name="sender"
                        languagesCms={languagesCms}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label="2. Oda Arkadaşı"
                      help={errors.reciver}
                      validateStatus={errors.reciver ? 'error' : 'success'}
                    >
                      <AttendeePicker3
                        record={data}
                        setRecord={setData}
                        name="reciver"
                        languagesCms={languagesCms}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label="3. Oda Arkadaşı"
                      help={errors.other}
                      validateStatus={errors.other ? 'error' : 'success'}
                    >
                      <AttendeePicker3
                        record={data}
                        setRecord={setData}
                        name="other"
                        languagesCms={languagesCms}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default RoomMateDetail;
