import React, { useEffect, useState, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import moment from 'moment';
import { Row, Col, Form, Input, Button, Switch, DatePicker, Card, message, Typography,Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { MapInput, FileInput, HashtagGroups, AttendeePicker } from '../components';
import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback } from "react";
import { env } from "../app.config";


const PostwallDetail = (props) => {

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
  const { TextArea } = Input;

  let newRecord = {
    comment: '',
    active: true,
    pinned: false,
    date: new Date(),
    medias: [],
    hashtagGroups: [], 
    mentionedUsers : []
  }

  let [data, setData] = useState(id ? {} : newRecord);
  let [loading, setLoading] = useState(id ? true : false);
  let [errors, setErrors] = useState([]);
  let [validationCheck, setValidationCheck] = useState(false)

  let [modules] = useGlobal("modules");
  let [languagesCms] = useGlobal("languagesCms"); 

  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true)
        api.get("/rest/posts/" + id).then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false)
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.active == null)
      errors.active = languagesCms.ENFORCED

    if (data.pinned == null)
      errors.pinned = languagesCms.ENFORCED

    if (data.date == null)
      errors.date = languagesCms.ENFORCED

    if (env.ISPOSTGROUP === 1) {
      if (data.hashtagGroups === [] || data.hashtagGroups.length === 0)
      errors.hashtagGroups = languagesCms.ENFORCED     
    }

    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      setLoading(true)
      if (!errors.hasError)
        if (id) {
          api.put("/rest/posts/" + id, data).then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success')
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            else
              message.error(result_message.message, 2);
            history.push('/postwall')
          })
        } else {
          api.post("/rest/posts", data).then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success')
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            else
              message.error(result_message.message, 2);
            history.push('/postwall')
          })
        }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/postwall">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
          <Form layout="horizontal" size={"large"} onFinish={save}>

            {id && <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.POST_OWNER}>
                  <Typography.Title style={{ padding: 0, margin: 0 }} level={4}>{data.attendee_name}</Typography.Title>
                </Form.Item>
              </Col>
            </Row>}

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.POST_CONTENT}  help={errors.comment} validateStatus={errors.comment ? 'error' : 'success'}>
                  <TextArea name="comment" value={data.comment} onChange={e => setData({ ...data, comment: e.target.value })} />
                </Form.Item>
              </Col>

            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.STATUS} help={errors.active} required validateStatus={errors.active ? 'error' : 'success'}>
                  <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.PIN} help={errors.active} required validateStatus={errors.active ? 'error' : 'success'}>
                  <Switch checked={data.pinned ? true : false} checkedChildren={languagesCms.SHOW} unCheckedChildren={languagesCms.DONTSHOW} onChange={v => setData({ ...data, pinned: v })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.GROUP} required={env.ISPOSTGROUP ? true : false} help={errors.hashtagGroups} validateStatus={errors.hashtagGroups ? 'error' : 'success'}>
                  <HashtagGroups languagesCms={languagesCms} record={data} setRecord={setData} name="hashtagGroups" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.MENTIONED_USERS} help={errors.mentionedUsers} validateStatus={errors.mentionedUsers ? 'error' : 'success'}>
                  <AttendeePicker languagesCms={languagesCms}  record={data} setRecord={setData} name="mentionedUsers" mode="single" />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.RELEASE_DATE} help={errors.date} required validateStatus={errors.date ? 'error' : 'success'}>
                  <DatePicker defaultValue={() => moment(data.date)} onChange={v => setData({ ...data, date: v })} format='DD/MM/YYYY HH:mm' showTime={true} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item label={languagesCms.LOCATION}>
                  <MapInput languagesCms={languagesCms} record={data} setRecord={setData} name="coordinate" disabled={false} title="coordinate" required={false} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item label={languagesCms.MEDIA}>
                  <FileInput languagesCms={languagesCms} name='medias' title='Medyalar' type='file' multi={true} ext={['image', 'video']} record={data} setRecord={setData} />
                  <Alert  message={languagesCms.POST_IMAGE_MESSAGE} banner />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </Card>
      </div>
    </div>
  );
};

export default PostwallDetail;
