import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import moment from 'moment';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import ExportJsonExcel from 'js-export-excel';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';

const Secondhand = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');

  let [modules] = useGlobal('modules');
  let [languagesCms] = useGlobal('languagesCms');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = '-createdAt';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name';
    }

    let restData = await api
      .get(`/rest/secondhand?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <CheckOutlined />;
            else item.active = <CloseOutlined />;
            if (item.type === 'estate') item.type = languagesCms.ESTATE;
            else if (item.type === 'car') item.type = languagesCms.CAR;
            else item.type = languagesCms.OTHER;
            if (item.sendNotification)
              item.sendNotification = <CheckOutlined />;
            else item.sendNotification = <CloseOutlined />;
            if (!item.hasOwnProperty('groups')) {
              item.groups = [];
            }
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/secondhand/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };
  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/secondhand/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let downloadExcel = async () => {
    var option = {};
    let dataTable = [];
    let excelData = await api
      .get(
        `/rest/secondhand?${qs.stringify({
          page: 1,
          pageSize: 10000,
        })}`,
        {},
      )
      .then(({ data }) => {
        return data.result.rows;
      });
    let max = 0;
    if (excelData) {
      for (let i in excelData) {
        if (excelData[i].medias) {
          for (let j = 0; j < excelData[i].medias.length; j++) {
            if (max < excelData[i].medias.length)
              max = excelData[i].medias.length;
          }
        }
      }
      for (let i in excelData) {
        if (excelData[i].type === 'estate')
          excelData[i].type = languagesCms.ESTATE;
        else if (excelData[i].type === 'car')
          excelData[i].type = languagesCms.CAR;
        else excelData[i].type = languagesCms.OTHER;
        let obj = {
          createdByName: excelData[i].createdByName
            ? excelData[i].createdByName
            : 'Kullanıcı Bulunamadı',
          name: excelData[i].name,
          phone: excelData[i].phone,
          type: excelData[i].type,
          content: excelData[i].content,
          city: excelData[i].city,
          price: excelData[i].price,
          estateType: excelData[i].estateType ? excelData[i].estateType : '',
          estateStatus: excelData[i].estateStatus
            ? excelData[i].estateStatus
            : '',
          estateM2: excelData[i].estateM2 ? excelData[i].estateM2 : '',
          carBrand: excelData[i].carBrand ? excelData[i].carBrand : '',
          carModelYear: excelData[i].carModelYear
            ? excelData[i].carModelYear
            : '',
          carKm: excelData[i].carKm ? excelData[i].carKm : '',
          productName: excelData[i].productName ? excelData[i].productName : '',
          createdAt: moment(excelData[i].createdAt).format('DD-MM-YYYY'),
        };

        if (max) {
          for (let index = 0; index < max; index++) {
            obj[`media_${index + 1}`] = excelData[i].medias[index]
              ? excelData[i].medias[index].url
              : '';
          }
        }
        dataTable.push(obj);
      }
    }

    let sheetAreas = [
      'createdByName',
      'name',
      'phone',
      'type',
      'content',
      'city',
      'price',
      'estateType',
      'estateStatus',
      'estateM2',
      'carBrand',
      'carModelYear',
      'carKm',
      'productName',
      'createdAt',
    ];

    let sheetHeader = [
      languagesCms.ADVERTISER,
      languagesCms.ADVERTISER_NAME,
      languagesCms.ADVERTISER_PHONE,
      languagesCms.TYPE,
      languagesCms.CONTENTS,
      languagesCms.CITY,
      languagesCms.PRICE,
      languagesCms.SELECT_ESTATE_TYPE,
      languagesCms.ESTATE_TYPE,
      languagesCms.ESTATE_M2,
      languagesCms.CAR_BRAND,
      languagesCms.CAR_MODEL_YEAR,
      languagesCms.CAR_KILOMETER,
      languagesCms.BRAND_MODEL_INFO,
      languagesCms.DATE,
    ];

    if (max) {
      for (let index = 1; index <= max; index++) {
        sheetAreas.push(`media_${index}`);
        sheetHeader.push(`Media_${index}`);
      }
    }
    option.fileName = module ? module.name : '';
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet',

        sheetFilter: sheetAreas,
        sheetHeader: sheetHeader,
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  let columns = [
    {
      title: languagesCms.TITLE,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ADVERTISER,
      dataIndex: 'createdByName',
      key: 'createdByName',
      sorter: (a, b) => a.createdByName - b.createdByName,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.PRICE,
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIVE,
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: languagesCms.TYPE,
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type - b.type,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/secondhand/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {isSmall ? '' : languagesCms.DELETE}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Button
            size="large"
            onClick={downloadExcel}
            style={{ marginRight: '5px' }}
            icon={<DownloadOutlined />}
          >
            {isSmall ? '' : 'Export Excel'}
          </Button>
          {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}
          <Link to="/secondhand/add">
            <Button type="light" icon={<PlusOutlined />} size="large">
              {isSmall ? '' : languagesCms.ADD_NEW}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH_ADVERT}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default Secondhand;
