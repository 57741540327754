import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  Card,
  message,
  Alert,
  InputNumber,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import {
  GroupPicker,
  SendNotificationInput,
  MediaWithQuestionsInput,
  LangPicker,
} from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';

const SurveysWithMedia2Detail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [languagesCms] = useGlobal('languagesCms');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');
  let lang;
  if (langCode) {
    lang = langCode;
  } else if (langCms && langCms[0].code) {
    lang = langCms[0].code;
  } else lang = 'TR';

  let newRecord = {
    lang: lang,
    name: '',
    groups: [],
    order: 0,
    singleAnswer: false,
  };

  let [data, setData] = useState(id ? {} : newRecord);
  let [answers, setAnswers] = useState();
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);

      api
        .get('/rest/surveysWithMedia2/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });

      api
        .get('/rest/surveyWithMedia2/countAnswers/' + id)
        .then(({ data: { result, result_message } }) => {
          setAnswers(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.lang == null) errors.lang = languagesCms.ENFORCED;

    if (data.name == null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED;

    if (data.questions == null || data.questions < 1)
      errors.questions = 'Hiç soru girmediniz';
    else
      data.questions.forEach((q, i) => {
        if (q.name == null || q.name.length < 1)
          errors.questions = `Soru metni boş geçilemez (${i + 1}. soru)`;
        else if (q.type === 'Media') {
          q.choices.forEach((item) => {
            if (item.type.length < 1) {
              errors.questions = `Media seçimli soru için en az bir tane işaretleme yapmalısınız)`;
            }
          });
          if (q.choices.length < 1)
            errors.questions = `Media seçimli soru için yeterli seçenek girmediniz. (${
              i + 1
            }. soru)`;
        } else if (q.type === 'Selected') {
          if (!q.childType)
            errors.questions =
              'Lütfen "Sonuçlu" Seçiminin Seçenek Tipini Seçiniz.';
          else if (q.choices.length < 2) {
            errors.questions = `Sonuçlu seçimi için yeterli soru seçeneği girmediniz (${
              i + 1
            }. soru)`;
          } else if (q.choices.filter((f) => f.correctAnswer).length < 1)
            errors.questions = `Sonuçlu seçimi için doğru seçeneği girmediniz (${
              i + 1
            }. soru)`;
        } else if (
          q.type !== 'Free' &&
          q.type !== 'Date' &&
          q.type !== 'DateRange' &&
          q.choices.length < 2
        ) {
          if (q.type === 'Media') return true;
          errors.name = `Yeterli soru seçeneği girmediniz (${i + 1}. soru)`;
        } else if (q.type !== 'Free')
          q.choices.forEach((c, j) => {
            if (q.type === 'TextToImage') return true;
            if (c.name === null || c.name.length < 1)
              errors.questions = `Seçenek boş geçilemez (${i + 1}. soru - ${
                j + 1
              }. seçenek)`;
          });
      });
    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/surveysWithMedia2/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/surveysWithMedia2');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/surveysWithMedia2', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/surveysWithMedia2');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/surveysWithMedia2">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.LANG}>
                  <LangPicker
                    langCms={langCms}
                    langCode={langCode}
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="lang"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.GROUP}
                  help={errors.groups}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <GroupPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="groups"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.SINGLE_ANSWER}
                  help={errors.singleAnswer}
                  validateStatus={errors.singleAnswer ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.singleAnswer ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, singleAnswer: v })}
                  />
                  <Alert message={languagesCms.AREA_ACTIVETE_MESSAGE} banner />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.ORDER}
                  required
                  help={errors.order}
                  validateStatus={errors.order ? 'error' : 'success'}
                >
                  <InputNumber
                    name="order"
                    value={data.order}
                    onChange={(v) => setData({ ...data, order: v })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.TITLE}
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.NOTIFICATION}
                  help={errors.sendNotification}
                  validateStatus={errors.sendNotification ? 'error' : 'success'}
                >
                  <SendNotificationInput
                    record={data}
                    setRecord={setData}
                    name="sendNotification"
                    languagesCms={languagesCms}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.STATUS}
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.active ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, active: v })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.QUESTIONS}
                  required
                  help={errors.questions}
                  validateStatus={errors.questions ? 'error' : 'success'}
                >
                  {answers > 0 && (
                    <Alert
                      type="info"
                      message={languagesCms.SURVEY_MESSAGE}
                      banner
                    />
                  )}
                  <MediaWithQuestionsInput
                    languagesCms={languagesCms}
                    name="questions"
                    record={data}
                    setRecord={setData}
                    disabled={answers > 0 ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default SurveysWithMedia2Detail;
