import React, { useGlobal } from 'reactn';
import { Row, Col, Card, Form, Typography, Image } from 'antd';

const Dashboard = (props) => {
  let [user] = useGlobal('user');

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Admin Bilgileri</h1>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={''}>
          <Form layout="horizontal" size={'large'}>
            <Row direction="row">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 10 }}
                lg={{ span: 10 }}
                xl={{ span: 6 }}
              >
                <Image width={200} src={user.avatar.url}>
                  {' '}
                </Image>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 14 }}
                lg={{ span: 14 }}
                xl={{ span: 18 }}
              >
                <Col xs={{ span: 24 }}>
                  <Typography.Title style={{ padding: 0, margin: 0 }} level={2}>
                    {[user.name, user.lastname].filter((x) => x).join(' ')}
                  </Typography.Title>
                </Col>
                <Col xs={{ span: 24 }}>
                  <Typography.Text>Email : {user.email}</Typography.Text>
                </Col>
                <Col xs={{ span: 24 }}>
                  <Typography.Text>Telefon : {user.phone}</Typography.Text>
                </Col>
                <Col xs={{ span: 24 }}>
                  <Typography.Text>Role : {user.role}</Typography.Text>
                </Col>
              </Col>
            </Row>
            <hr />
            <hr />
            <Row direction="row">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 20 }}
              >
                <Col xs={{ span: 24 }}>
                  <Typography.Title
                    style={{ textAlign: 'center' }}
                    level={5}
                  ></Typography.Title>
                </Col>
              </Col>
            </Row>
            <hr />
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
