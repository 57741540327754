import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name } = props;
  let [sessions, setSession] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 100 };
  let languagesCms = props.languagesCms;

  useMemo(() => {
    api
      .get(`/rest/sessionOrderItem?${qs.stringify(_params)}`)
      .then(({ data }) => {
        if (data && data.result && data.result.rows.length > 0) {
          let dbSessions = data.result.rows.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
          setSession(dbSessions);
        }
      });
  }, []);

  return (
    <Select
      value={record[name] || []}
      mode="multiple"
      placeholder={languagesCms.SELECT_SESSION}
      onChange={(v) => setRecord({ ...record, [name]: v })}
    >
      {sessions &&
        sessions.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
