import React, {useEffect, useState, useGlobal} from "reactn";
import {useParams} from 'react-router-dom'
import moment from 'moment';
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Switch,
    DatePicker,
    Card,
    message,
    InputNumber,
    Typography,
    Alert,
    Select
} from 'antd';
import {useHistory} from 'react-router-dom';
import {LeftOutlined} from "@ant-design/icons";
import {env} from "../app.config";
import {FileInput, FileInputSingle, ModuleStoriePicker, ItemByStorieModulePicker, HashtagGroups} from '../components';
import {ChromePicker} from "react-color";
import rgbHex from "rgb-hex";
import api from '../service/index'
import {Link} from 'react-router-dom';
import {useCallback} from "react";

const StorieDetail = (props) => {
    let params = useParams()
    let history = useHistory()
    let refId = params.refId && params.refId;
    let id = params.id !== "add" ? params.id : false;
    let linkTypes = {"scrollUp": "Yukarı Kaydır", "button": "Buton"};
    let [isSmall, ] = useGlobal('isSmall')
    let newRecord = {
        active: true,
        items: [],
        title: '',
        name: '',
        order: 0,
        hashtagGroups: [],
        startDate: new Date(),
        endDate: new Date(),
        linkType: '',
        item_id: '',
        swipeText: '',
        linkUrl: '',
        buttonText: '',
        buttonTextColor: '#000000',
        buttonBackgroundColor: '#FFFFFF',
        isDirect: false,
    }
    let [data, setData] = useState(id ? [] : newRecord);
    let [extraData, setExtraData] = useState(null);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false)
    let [moduleChange, setModuleChange] = useState(false);
    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms"); 
    let [langCode] = useGlobal("langCode");
    let [langCms] = useGlobal("langCms");
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }
    useEffect(() => {
        if (modules && id) {
            setLoading(true)
            api.get("/rest/stories/" + id).then(({data: {result, result_message}}) => {
                if (result.hasOwnProperty('userId')) {
                    setExtraData({userId: result.userId})
                    setData({...result, userId: result.userId._id});

                } else {
                    setExtraData({userId: result.userId})
                    setData(result);
                }
                setLoading(false)
            });
        }
    }, [id]);

    useEffect(() => {
        if (modules && refId) {
            setLoading(true)
            api.get("/rest/stories/" + refId).then(({data: {result, result_message}}) => {
                setData({...data, name: result ? result.name : "", refId: refId, icon: result ? result.icon : ""})
                setLoading(false)
            });
        }
    }, [refId])

    let validate = useCallback(() => {
        let errors = {};

        if (data.icon) {
            if (!data.name) {
                errors.name = 'İcon girilmiş ise başlık alanı zorunludur!'
            }
        }
        if (data.linkType && data.linkType === "button") {
            if (!data.buttonText)
                errors.buttonText = languagesCms.ENFORCED

            if (!data.buttonBackgroundColor)
                errors.buttonBackgroundColor = languagesCms.ENFORCED

            if (!data.buttonTextColor)
                errors.buttonTextColor = languagesCms.ENFORCED
        }
        if (data.linkType && data.linkType === "scrollUp") {
            if (!data.swipeText) {
                errors.swipeText = languagesCms.ENFORCED
            }
        }
        if (data.order == null)
            errors.order = languagesCms.ENFORCED

        if (data.items == null || data.items.length === 0)
            errors.items = languagesCms.ENFORCED

        if (data.startDate == null)
            errors.startDate = languagesCms.ENFORCED

        if (data.endDate == null)
            errors.endDate = languagesCms.ENFORCED
        
        if (env.ISPOSTGROUP === 1) {
            if (data.hashtagGroups === [] || data.hashtagGroups.length === 0)
            errors.hashtagGroups = languagesCms.ENFORCED     
        }
        
        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => {
        if (validationCheck) setErrors(validate())
    }, [validationCheck, data, validate]);


    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({top: 20, behavior: 'smooth'});
        } else {
            if (id) {
                api.put("/rest/stories/" + id, data).then(({data: {result, result_message}}) => {
                    if (result_message.type === 'success')
                        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                    else
                        message.error(result_message.message, 2);
                    history.push('/stories')
                })
            } else {
                api.post("/rest/stories", data).then(({data: {result, result_message}}) => {
                    if (result_message.type === 'success')
                        message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                    else
                        message.error(result_message.message, 2);
                    history.push('/stories')
                })
            }
        }
    };

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/stories">
                        <Button type="light" icon={<LeftOutlined/>}
                                size={'large'}>{!isSmall && languagesCms.BACK}</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>

                        <Row direction="row">

                            {id && <Col xs={{span: 24}} md={{span: 24}}>
                                <Form.Item label={languagesCms.POST_OWNER}>
                                    {extraData && extraData.userId &&
                                        <Typography.Title style={{padding: 0, margin: 0}}
                                                          level={4}>{[extraData.userId.name, extraData.userId.lastname].filter(x => x).join(' ')}</Typography.Title>
                                    }
                                    {extraData && !(extraData.userId) &&
                                        <Typography.Title style={{padding: 0, margin: 0}}
                                                          level={4}>{"Daha önce silinmiş bir kullanıcının paylaşımıdır. Düzenlenemez!"}</Typography.Title>
                                    }
                                </Form.Item>
                            </Col>}

                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.TITLE} help={errors.name}
                                           validateStatus={errors.name ? 'error' : 'success'}>
                                    {!refId && <><Input name="name" value={data.name}
                                                        onChange={e => setData({...data, name: e.target.value})}/>
                                        <br/><small>{languagesCms.STORIE_ALERT4}</small></>}
                                    {refId && <Typography.Title style={{padding: 0, margin: 0}}
                                                                level={4}>{data.name}</Typography.Title>}
                                </Form.Item>
                            </Col>

                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.STATUS} help={errors.active}
                                           validateStatus={errors.active ? 'error' : 'success'}>
                                    <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE}
                                            unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({...data, active: v})}/>
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label={languagesCms.GROUP} required={env.ISPOSTGROUP ? true : false} help={errors.hashtagGroups} validateStatus={errors.hashtagGroups ? 'error' : 'success'}>
                                <HashtagGroups languagesCms={languagesCms} record={data} setRecord={setData} name="hashtagGroups" />
                                </Form.Item>
                            </Col>

                            <Col xs={{span: 24}} md={{span: 24}}>
                                <Form.Item label={languagesCms.EXPLANATION} help={errors.title}
                                           validateStatus={errors.title ? 'error' : 'success'}>
                                    <Input name="title" value={data.title}
                                           onChange={e => setData({...data, title: e.target.value})}/>
                                </Form.Item>
                            </Col>

                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.SELECT_LINK_TYPE}>
                                    <Select value={linkTypes[data.linkType]} mode='single' placeholder={languagesCms.LINK_TYPE}
                                            onChange={value => setData({...data, linkType: value})}>
                                        {Object.keys(linkTypes).map((key, i) => (
                                            <Select.Option value={key} key={i}>{linkTypes[key]}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.REDIRECT_APP} help={errors.isDirect}
                                           validateStatus={errors.isDirect ? 'error' : 'success'}>
                                    <Switch checked={data.isDirect ? true : false} checkedChildren={languagesCms.ACTIVE}
                                            unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({...data, isDirect: v})}/>
                                </Form.Item>
                            </Col>

                            {data.isDirect === false &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="Link Url" help={errors.linkUrl}
                                               validateStatus={errors.linkUrl ? 'error' : 'success'}>
                                        <Input name="linkUrl" value={data.linkUrl}
                                               onChange={e => setData({...data, linkUrl: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            }

                            {data.linkType === "scrollUp" &&
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="Link Url Text" help={errors.swipeText}
                                               validateStatus={errors.swipeText ? 'error' : 'success'}>
                                        <Input name="swipeText" value={data.swipeText}
                                               onChange={e => setData({...data, swipeText: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            }

                            {data.linkType === "button" &&
                                <>
                                    <Col xs={{span: 24}} md={{span: 8}}>
                                        <Form.Item label="Buton text" help={errors.buttonText}
                                                   validateStatus={errors.buttonText ? 'error' : 'success'}>
                                            <Input name="buttonText" value={data.buttonText}
                                                   onChange={e => setData({...data, buttonText: e.target.value})}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} md={{span: 8}}>
                                        <Form.Item label="Buton text Color" help={errors.buttonTextColor}
                                                   validateStatus={errors.buttonTextColor ? 'error' : 'success'}>
                                            <ChromePicker
                                                disableAlpha
                                                color={data.buttonTextColor}
                                                onChange={x => setData({
                                                    ...data,
                                                    buttonTextColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                                                })}
                                            /> </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} md={{span: 8}}>
                                        <Form.Item label="Buton Background Color" help={errors.buttonBackgroundColor}
                                                   validateStatus={errors.buttonBackgroundColor ? 'error' : 'success'}>
                                            <ChromePicker
                                                disableAlpha
                                                color={data.buttonBackgroundColor}
                                                onChange={x => setData({
                                                    ...data,
                                                    buttonBackgroundColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                                                })}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            }

                            {data.isDirect === true &&
                                <>
                                    <Col xs={{span: 24}} md={{span: 12}}>
                                        <Form.Item label={languagesCms.OPEN_MODULE} required help={errors.moduleId}
                                                   validateStatus={errors.moduleId ? 'error' : 'success'}>
                                            <ModuleStoriePicker languagesCms={languagesCms} langCode={langCode} langCms={langCms} record={data} setRecord={setData}
                                                                moduleChange={moduleChange}
                                                                setModuleChange={setModuleChange} name="moduleId"/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} md={{span: 12}}>
                                        <Form.Item label={languagesCms.SHARING} required help={errors.item_id}
                                                   validateStatus={errors.moduleId ? 'error' : 'success'}>
                                            <ItemByStorieModulePicker languagesCms={languagesCms} record={data} setRecord={setData} name="item_id"
                                                                      moduleChange={moduleChange}
                                                                      disabled={data.moduleId === "not_modul" || data.moduleId === "notifications" || !data.moduleId ? true : false}/>
                                        </Form.Item>
                                    </Col>
                                </>
                            }

                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.ORDER} required validateStatus={errors.name ? 'error' : 'success'}>
                                    <InputNumber name="order" min={0} defaultValue={0} value={data.order}
                                                 onChange={v => setData({...data, order: v})}/>
                                </Form.Item>
                            </Col>

                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.START_DATE} required help={errors.startDate}
                                           validateStatus={errors.startDate ? 'error' : 'success'}>
                                    <DatePicker defaultValue={() => moment(data.startDate)}
                                                onChange={v => setData({...data, startDate: v})}
                                                format='DD/MM/YYYY HH:mm' showTime={true}/>
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.END_DATE} required help={errors.endDate}
                                           validateStatus={errors.endDate ? 'error' : 'success'}>
                                    <DatePicker defaultValue={() => data.endDate = moment().add(1, 'day')}
                                                onChange={v => setData({...data, endDate: v})} format='DD/MM/YYYY HH:mm'
                                                showTime={true}/> </Form.Item>
                            </Col>

                            <Col xs={{span: 24}} md={{span: 24}}>
                                <Alert
                                    message={languagesCms.STORIE_ALERT1}
                                    banner/>
                            </Col>

                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label={languagesCms.MEDIA} required help={errors.items}
                                           validateStatus={errors.items ? 'error' : 'success'}>
                                    <FileInput languagesCms={languagesCms} name='items' title='Medyalar' type='file' multi={true}
                                               ext={['image', 'video']} record={data} setRecord={setData}/>
                                    <Alert message={languagesCms.BANNER_PIXEL_MESSAGE}
                                           banner/>
                                    <Alert message={languagesCms.STORIE_ALERT2} banner/>
                                </Form.Item>
                            </Col>

                            <Col xs={{span: 24}} md={{span: 12}}>
                                <Form.Item label="İcon">
                                    <FileInputSingle languagesCms={languagesCms} name='icon' title='Iconlar' type='file' multi={true}
                                                     ext={['image']} record={data} setRecord={setData}
                                                     disabled={refId && true}/>
                                    <br/><small>{languagesCms.STORIE_ALERT3}</small>
                                    <Alert message={languagesCms.STORIE_ICON_SIZE} banner/>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" disabled={loading} htmlType="submit" size="large"
                                            block> {languagesCms.SAVE} </Button>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </Card>

            </div>
        </div>
    );
};


export default StorieDetail;