import React, { useEffect, useState, useGlobal, useRef } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Button,
  Card,
  message,
  DatePicker,
  Modal,
  Input,
  Select,
  Switch,
  Popconfirm,
} from 'antd';
import { LeftOutlined, DeleteOutlined } from '@ant-design/icons';
import ImageMapper from 'react-image-mapper';
import { Link } from 'react-router-dom';
import Parse from 'parse';
import dayjs from 'dayjs';
import moment from 'moment';
import { ReservationUsersPicker } from '../components';
const { RangePicker } = DatePicker;

const PlanReservation = (props) => {
  let params = useParams();
  let placeId = params.placeId !== 'add' ? params.placeId : false;
  let id = params.id !== 'add' ? params.id : false;
  let nowDate = dayjs(new Date()).format('YYYY-MM-DD').toString();

  let newRecord = {};
  let [data, setData] = useState(id ? {} : newRecord);
  let [loading, setLoading] = useState(id ? true : false);

  let [image, setImage] = useState('');
  let [areas, setAreas] = useState(null);
  let [reservations, setReservations] = useState(null);
  let [coords, setCoords] = useState(null);
  let [modal, setModal] = useState(null);
  let [map, setMap] = useState({});

  let [date, setDate] = useState(moment(new Date()));
  let [startDate, setStartDate] = useState(moment(new Date()));
  let [endDate, setEndDate] = useState(moment(new Date()));
  let [languagesCms] = useGlobal('languagesCms');
  let [radius, setRadius] = useState(10);
  let [imageWidth, setImageWidth] = useState(null);
  let [imageHeight, setImageHeight] = useState(null);
  let [componentWidth, setComponentWidth] = useState(null);
  let [componentHeight, setComponentHeight] = useState(null);
  let [loaded, setLoaded] = useState(false);
  let [sideRights, setSideRights] = useState({
    lunch: true,
    service: true,
  });

  let mapper = useRef();
  let componentMapper = useRef();

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let [customization] = useGlobal('customization');
  let parseServerURL = customization.parseServerURL;
  let parseAppId = customization.parseAppId;

  Parse.initialize(parseAppId);
  Parse.serverURL = parseServerURL;
  let Place = Parse.Object.extend('Place');
  let Plan = Parse.Object.extend('Plan');
  let PlanCoordinates = Parse.Object.extend('PlanCoordinates');
  let Reservation = Parse.Object.extend('Reservation');
  let User = Parse.Object.extend('User');

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      setStartDate(moment(nowDate + ' 00:00'));
      setEndDate(moment(nowDate + ' 23:59'));

      let query = new Parse.Query(Plan);
      query.get(id).then(
        (plan) => {
          setData(plan.attributes);
          setImage(plan.attributes.planImageUrl.url);
          setImageWidth(plan.attributes.planImageUrl.width);
          setImageHeight(plan.attributes.planImageUrl.height);
          setComponentWidth(plan.attributes.planImageUrl.width);
          setComponentHeight(plan.attributes.planImageUrl.height);
          setLoading(false);
          setLoaded(false);
          //yan hakların belirlenmesi
          let queryPlace = new Parse.Query(Place);
          queryPlace
            .get(plan.attributes.placeId.id)
            .then(function (results) {
              setSideRights({
                lunch: results.attributes.diningHall,
                service: results.attributes.service,
              });
            })
            .catch(function (error) {
              message.error('Bina bilgilerine ulaşılamadı.', 2);
            });
        },
        (error) => {
          message.error('Kayıt bilgilerine ulaşılamadı.', 2);
        },
      );
    }
  }, [id]);

  useEffect(() => {
    if (imageWidth && imageHeight) {
      //koordinat bilgilerinin alınması ve arealara dönüştürülmesi
      let queryCoordinates = new Parse.Query(PlanCoordinates);
      queryCoordinates.containedIn('planId', [id]);
      queryCoordinates.equalTo('isAvailable', true);
      queryCoordinates
        .find()
        .then(function (results) {
          setAreas(
            results.map((item, key) => {
              let itemAtt = item.attributes;
              let x = (itemAtt.x * componentWidth) / imageWidth;
              let y = (itemAtt.y * componentHeight) / imageHeight;
              return {
                id: item.id,
                name: itemAtt.name,
                shape: 'circle',
                coords: [x, y, radius],
                preFillColor: 'green',
                fillColor: '#0000ff',
              };
            }),
          );
        })
        .catch(function (error) {
          setAreas([]);
        });

      //rezervasyon bilgilerinin alınması ve arealara dönüştürülmesi
      let reservationDate = dayjs(date._d).format('DD-MM-YYYY').toString();

      let queryReservetions = new Parse.Query(Reservation);
      queryReservetions.containedIn('floorId', [id]);
      queryReservetions
        .find()
        .then(function (results) {
          results = results.filter(
            (x) =>
              dayjs(x.get('date')).format('DD-MM-YYYY').toString() ===
              reservationDate,
          );
          setReservations(
            results.map((item, key) => {
              let itemAtt = item.attributes;
              return {
                id: item.id,
                coordinateId: itemAtt.itemId,
                name: '',
                users: itemAtt.users.id,
                shape: 'circle',
                coords: [itemAtt.x, itemAtt.y, radius],
                preFillColor: 'red',
                fillColor: '#0000ff',
              };
            }),
          );
        })
        .catch(function (error) {
          setReservations([]);
        });
    }
    setLoaded(false);
  }, [
    imageWidth,
    imageHeight,
    componentWidth,
    componentHeight,
    radius,
    loaded,
  ]);

  //areas ve rezervasyonların birleştirilmesi
  useEffect(() => {
    if (areas && reservations) {
      setAreas(
        areas.map((area) => {
          let reservation = reservations.find(
            (y) => y.coordinateId === area.id,
          );
          if (reservation) {
            area.users = reservation.users;
            area.reservationId = reservation.id;
            area.preFillColor = reservation.preFillColor;
          }
          return area;
        }),
      );
    }
  }, [reservations]);

  //areas her yenilendiğinde kullanılan map bilgisinin de güncellenmesi için
  useEffect(() => {
    setMap({
      name: 'my-map',
      areas: areas,
    });
    setLoaded(false);
  }, [areas]);

  // ekran boyutuna göre ayarlama işlemleri
  if (componentMapper.current) {
    if (
      componentMapper.current.offsetWidth &&
      componentMapper.current.offsetWidth !== componentWidth
    ) {
      setComponentWidth(componentMapper.current.offsetWidth);
      if (componentMapper.current.offsetWidth > 800) {
        setRadius(10);
      }
      if (
        componentMapper.current.offsetWidth < 800 &&
        componentMapper.current.offsetWidth > 600
      ) {
        setRadius(7);
      }
      if (
        componentMapper.current.offsetWidth < 600 &&
        componentMapper.current.offsetWidth > 400
      ) {
        setRadius(5);
      }
      if (
        componentMapper.current.offsetWidth < 400 &&
        componentMapper.current.offsetWidth > 0
      ) {
        setRadius(3);
      }
    }
    if (
      componentMapper.current.offsetHeight &&
      componentMapper.current.offsetHeight !== componentHeight
    ) {
      setComponentHeight(componentMapper.current.offsetHeight);
    }
  }

  //tarih seçilme ve tariha göre rezervasyonların getirilmesi
  let onChangeDatePicker = async (date, dateString) => {
    //rezervasyon eklenirken kullanılacak tarihin belirlenmesi
    setDate(moment(dateString + ' 14:00'));
    setStartDate(moment(dateString + ' 00:00'));
    setEndDate(moment(dateString + ' 23:59'));

    //o plandaki koordinatların bulunması ve arealar şekline dönüştürülmesi
    let queryCoordinates = new Parse.Query(PlanCoordinates);
    let coordinates = [];
    queryCoordinates.containedIn('planId', [id]);
    queryCoordinates.equalTo('isAvailable', true);
    await queryCoordinates
      .find()
      .then(function (results) {
        coordinates = results.map((item, key) => {
          let itemAtt = item.attributes;
          let x = (itemAtt.x * componentWidth) / imageWidth;
          let y = (itemAtt.y * componentHeight) / imageHeight;
          return {
            id: item.id,
            name: itemAtt.name,
            shape: 'circle',
            coords: [x, y, radius],
            preFillColor: 'green',
            fillColor: '#0000ff',
          };
        });
      })
      .catch(function (error) {
        coordinates = [];
      });

    //o plandaki ve tarihteki rezervasyonların bulunması arealar haline dönüştürülmes
    let reservations = [];
    let plandate = dayjs(dateString).format('DD-MM-YYYY').toString();
    let queryReservetions = new Parse.Query(Reservation);
    queryReservetions.containedIn('floorId', [id]);
    await queryReservetions
      .find()
      .then(function (results) {
        results = results.filter(
          (x) =>
            dayjs(x.get('date')).format('DD-MM-YYYY').toString() === plandate,
        );
        reservations = results.map((item, key) => {
          let itemAtt = item.attributes;
          return {
            id: item.id,
            coordinateId: itemAtt.itemId,
            name: '',
            users: itemAtt.users.id,
            shape: 'circle',
            coords: [itemAtt.x, itemAtt.y, radius],
            preFillColor: 'red',
            fillColor: '#0000ff',
          };
        });
      })
      .catch(function (error) {
        reservations = [];
      });

    //hangi coordinatta hangi rezervasyon varsa ona göre birleştirme işlemi
    if (coordinates && reservations) {
      setAreas(
        coordinates.map((area) => {
          let reservation = reservations.find(
            (y) => y.coordinateId === area.id,
          );
          if (reservation) {
            area.users = reservation.users;
            area.reservationId = reservation.id;
            area.preFillColor = reservation.preFillColor;
          }
          return area;
        }),
      );
    }
    //ekrana gözükmesi için verileri gönderme işlemi
    mapper.current.props.map.areas = coordinates;
  };

  let onChangeRangePicker = async (date, dateString) => {
    setStartDate(moment(dateString[0] + ' 00:00'));
    setEndDate(moment(dateString[1] + ' 23:59'));
  };

  //pop up açılması için doldurulması gerekn bilgiler
  let clickedArea = async (area) => {
    let coordinate = {};
    let coordinateR = {};
    //areadan gelen koordinatlara göre bilgilerin çekilmesi
    let queryCoordinates = new Parse.Query(PlanCoordinates);
    await queryCoordinates
      .get(area.id)
      .then(function (result) {
        coordinate = result;
        coordinateR = { ...result.attributes, id: result.id };
      })
      .catch(function (error) {
        coordinate = {};
        coordinateR = {};
      });

    //rezervasyon varsa rezervasyon bilgilerinin çekilmesi
    if (area.reservationId) {
      let queryReservation = new Parse.Query(Reservation);
      queryReservation.containedIn('objectId', [area.reservationId]);
      await queryReservation
        .find()
        .then(function (results) {
          setCoords({
            ...coordinateR,
            users: results[0].attributes.users.id,
            reservationId: results[0].id,
            lunch: results[0].attributes.lunch,
            service: results[0].attributes.service,
          });
        })
        .catch(function (error) {
          setCoords({ coordinateR });
        });
    } else {
      setCoords({ ...coordinate.attributes, id: coordinate.id });
    }
  };

  let clickOk = async (reservation) => {
    if (reservation.users) {
      let user = '';
      let message = 'Rezervasyon bilgileri kaydediliyor, onaylıyor muzunuz?';

      let query = new Parse.Query(Reservation);
      query.equalTo('itemId', reservation.id);
      query.greaterThanOrEqualTo('date', startDate._d);
      query.lessThanOrEqualTo('date', endDate._d);
      await query.find().then(
        (object) => {
          object.forEach((element) => {
            if (element) {
              let name =
                dayjs(element.attributes.date).format('DD-MM-YYYY').toString() +
                ' - ' +
                [
                  element.attributes.users.attributes.name,
                  element.attributes.users.attributes.lastName,
                ]
                  .filter((x) => x)
                  .join(' ');

              user = [user, name].filter((x) => x).join(', ');
            }
          });
        },
        (error) => {
          message.error('Kullanıcı bilgilerine ulaşılamadı.', 2);
        },
      );

      if (user !== '') {
        message =
          'Seçtiğiniz tarihler arasında, seçtiğiniz masada aşagıdaki kullanıcılara ait rezervasyonlar vardır. Devam ederseniz mevcut rezervasyonlar iptal oalcaktır. Yine de ilerlemek istiyor musunuz?';
      }

      setModal({
        message: message,
        users: user,
        reservation: reservation,
      });
    } else message.error('Kullanıcı seçmediniz!', 2);
  };

  let clickedOk = async (reservation) => {
    let user = {};
    let planCoordinates = {};

    //rezervasyon yapılmak istenen kullanıcı bilgileri alınıyor
    let query = new Parse.Query(User);
    query.equalTo('objectId', reservation.users);
    await query.first().then(
      (object) => {
        user = object;
      },
      (error) => {
        message.error('Kullanıcı bilgilerine ulaşılamadı.', 2);
      },
    );

    //rezervasyon yapılmak istenen kordinat bilgileri alınıyor
    let queryCoordinates = new Parse.Query(PlanCoordinates);
    queryCoordinates.equalTo('objectId', reservation.id);
    await queryCoordinates.first().then(
      (object) => {
        planCoordinates = object;
      },
      (error) => {
        message.error('Oturma alanı bilgilerine ulaşılamadı.', 2);
      },
    );

    if (user) {
      let query = new Parse.Query(Reservation);
      query.equalTo('itemId', reservation.id);
      query.greaterThanOrEqualTo('date', startDate._d);
      query.lessThanOrEqualTo('date', endDate._d);
      await query.find().then(
        (object) => {
          object.forEach((element) => {
            if (element) {
              element.destroy().then(
                (object) => {
                  message.success('Diğer kayıtlar silindi', 2);
                },
                (error) => {
                  message.error(languagesCms.ERROR_DELETE, 2);
                },
              );
            }
          });
        },
        (error) => {
          message.error('Kullanıcı bilgilerine ulaşılamadı.', 2);
        },
      );

      for (let m = startDate; m.isBefore(endDate); m.add(1, 'days')) {
        let d = moment(m.format('YYYY-MM-DD')).add(14, 'hours');

        //eklenecek rezervasyon bilgileri toplanıyor
        let myReservation = new Reservation();
        let myReservationData = {
          floorId: id,
          itemId: reservation.id,
          PlanCoordinates: planCoordinates,
          users: user,
          userId: user.attributes.user_id,
          status: 'Reserved',
          date: d._d,
          lunch: reservation.lunch || false,
          service: reservation.service || false,
          isReservationLunch: false,
          reservationTitle:
            data.placeId.attributes.name +
            '/' +
            data.title +
            '/' +
            planCoordinates.attributes.name,
        };

        //rezervasyon bilgileri ekleniyor
        await myReservation.save(myReservationData).then(
          (newReservation) => {
            let data = { ...newReservation.attributes, id: newReservation.id };

            //yeni rezervasyon ekranda kırmızı renkte gözüksün diye veriler yenileniyor
            if (startDate.format('DD-MM-YYYY') === d.format('DD-MM-YYYY')) {
              let newData = areas.filter((area) => area.id !== reservation.id);
              let x = (reservation.x * componentWidth) / imageWidth;
              let y = (reservation.y * componentHeight) / imageHeight;

              let newArea = {
                id: reservation.id,
                reservationId: data.id,
                coordinateId: data.itemId,
                name: reservation.name,
                users: reservation.users,
                shape: 'circle',
                coords: [x, y, radius],
                preFillColor: 'red',
                fillColor: '#0000ff',
              };
              setAreas([...newData, newArea]);
              mapper.current.props.map.areas = [...areas, newArea];
            }
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
          },
          (error) => {
            message.error(error, 2);
          },
        );

        setModal(null);
        setCoords(null);
      }

      setStartDate(moment(date.format('YYYY-MM-DD').toString() + ' 00:00'));
      setEndDate(moment(date.format('YYYY-MM-DD').toString() + ' 23:59'));
    } else message.error('Kullanıcı seçmediniz!', 2);
  };

  //rezervasyon silme
  let deleteCoordinate = async (reservationId) => {
    //rezervasyon bilgileri alınıyor
    let query = new Parse.Query(Reservation);
    query.equalTo('objectId', reservationId);
    let reservation = await query.first();
    let coordinateId = reservation.attributes.itemId;

    //silinecek rezervsyonun coordinat bilgileri bulunuyor
    let queryCooridnate = new Parse.Query(PlanCoordinates);
    queryCooridnate.equalTo('objectId', coordinateId);
    let coordinate = await queryCooridnate.first();
    let coordinateData = { ...coordinate.attributes, id: coordinate.id };

    //rezervasyon siliniyor
    reservation.destroy().then(
      () => {
        //silinen rezervasyonun ekranda gözükmesi için yveriler yenileniyor
        let newData = areas.filter((area) => area.id !== coordinateData.id);
        let x = (coordinateData.x * componentWidth) / imageWidth;
        let y = (coordinateData.y * componentHeight) / imageHeight;
        let newArea = {
          id: coordinateData.id,
          name: coordinateData.name,
          shape: 'circle',
          coords: [x, y, radius],
          preFillColor: 'green',
          fillColor: '#0000ff',
        };
        setAreas([...newData, newArea]);
        mapper.current.props.map.areas = [...areas, newArea];
        message.success('Kayıt silindi', 2);
      },
      (error) => {
        message.error(languagesCms.ERROR_DELETE, 2);
      },
    );
    setCoords(null);
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''} - Rezervasyon İşlemleri</h1>
        </div>
        <div className="list-buttons">
          <Link to={'/place/' + placeId + '/plan'}>
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>{' '}
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'}>
            <Row direction="row">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 14 }}
                lg={{ span: 14 }}
                xl={{ span: 18 }}
              >
                <Row direction="row">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                  >
                    <ul>
                      <li>
                        Oturma alanlarına tıklayarak ilgili kişilere rezervasyon
                        işlemini gerçekleştirebilirisiniz.
                      </li>
                      <li>
                        <b>Yeşil</b> alanlar rezervaysona açık alanlardır.
                      </li>
                      <li>
                        <b>Kırmızı</b> alanlar rezerve edilmiş alanlardır.
                      </li>
                    </ul>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                  >
                    <p> Rezervsyon tarihini seçiniz:</p>
                    <DatePicker
                      onChange={onChangeDatePicker}
                      defaultValue={() => moment(date)}
                    />
                  </Col>
                </Row>
              </Col>
              {modal && (
                <div>
                  <Modal
                    title={''}
                    visible={true}
                    onOk={() => clickedOk(coords)}
                    onCancel={() => setModal(null)}
                  >
                    <Card title={'Uyarı'} loading={modal ? false : true}>
                      <p>{modal.message}</p>
                      <p>{modal.users}</p>
                    </Card>
                  </Modal>
                </div>
              )}
              {coords && (
                <div>
                  {/*koordinatlar üzerine tıklanınca açılacak pop up. Koordinat bilgileri ve varsa rezervasyon bilgileri içerir*/}
                  <Modal
                    title={''}
                    visible={true}
                    onOk={() => clickOk(coords)}
                    onCancel={() => setCoords(null)}
                  >
                    <Card
                      title={
                        coords.reservationId
                          ? languagesCms.EDIT
                          : languagesCms.ADD_NEW
                      }
                      loading={coords ? false : true}
                    >
                      <Form>
                        <Row direction="row">
                          <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Form.Item label="isim">
                              <Input
                                name="name"
                                disabled={true}
                                value={coords.name}
                                onChange={(e) =>
                                  setCoords({ ...coords, name: e.target.value })
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row direction="row">
                          <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Form.Item label={languagesCms.TYPE}>
                              <Select
                                defaultValue={coords.type}
                                disabled={true}
                                style={{ width: 250 }}
                                onChange={(v) => {
                                  setCoords({ ...coords, type: v });
                                }}
                              >
                                <Select.Option value="public">
                                  Havuz Masa
                                </Select.Option>
                                <Select.Option value="admin">
                                  Admin Only
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row direction="row">
                          <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Form.Item label={languagesCms.DATE}>
                              <RangePicker
                                onChange={onChangeRangePicker}
                                defaultValue={[
                                  moment(startDate),
                                  moment(endDate),
                                ]}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row direction="row">
                          <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Form.Item label={languagesCms.USERS} required>
                              <ReservationUsersPicker
                                languagesCms={languagesCms}
                                record={coords}
                                setRecord={setCoords}
                                name="users"
                                parseServerURL={parseServerURL}
                                parseAppId={parseAppId}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row direction="row">
                          {sideRights.lunch && (
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                              <Form.Item label="Yemek">
                                <Switch
                                  checked={coords.lunch ? true : false}
                                  checkedChildren="kullanılacak"
                                  unCheckedChildren="kullanılmayacak"
                                  onChange={(v) =>
                                    setCoords({ ...coords, lunch: v })
                                  }
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {sideRights.service && (
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                              <Form.Item label="Servis">
                                <Switch
                                  checked={coords.service ? true : false}
                                  checkedChildren="kullanılacak"
                                  unCheckedChildren="kullanılmayacak"
                                  onChange={(v) =>
                                    setCoords({ ...coords, service: v })
                                  }
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </Row>
                        {coords.reservationId && (
                          <Popconfirm
                            onConfirm={() =>
                              deleteCoordinate(coords.reservationId)
                            }
                            title={languagesCms.CHECK_DELETE}
                            okText={languagesCms.OK_TEXT}
                            cancelText={languagesCms.CANCEL_TEXT}
                          >
                            <Button type="danger" icon={<DeleteOutlined />}>
                              Rezervasyon Sil
                            </Button>
                          </Popconfirm>
                        )}
                      </Form>
                    </Card>
                  </Modal>
                </div>
              )}
              {map.areas && (
                <Col ref={componentMapper}>
                  {areas && image && (
                    <ImageMapper
                      ref={mapper}
                      src={image || ''}
                      map={map}
                      width={componentWidth}
                      onClick={(area) => clickedArea(area)}
                      onLoad={() => setLoaded(true)}
                    />
                  )}
                </Col>
              )}
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default PlanReservation;
