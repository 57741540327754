import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name } = props;
  let [groups, setGroup] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 100 };
  let languagesCms = props.languagesCms;
  useEffect(() => {
    api
      .get(`/rest/attendeeGroups?${qs.stringify(_params)}`)
      .then(({ data }) => {
        if (data && data.result && data.result.rows.length > 0) {
          let dbGroups = data.result.rows.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
          setGroup(dbGroups);
        }
      });
  }, []);

  let hashtagGroup = (v) => {
    let group = v.map((x) => {
      let hashtagGroup = groups.find((e) => e._id === x);
      return {
        name: hashtagGroup.name,
        _id: hashtagGroup._id,
        isSelected: true,
      };
    });
    setRecord({ ...record, [name]: group });
  };

  return (
    <Select
      defaultValue={record[name] ? record[name].map((e) => e._id) : []}
      mode="multiple"
      placeholder={languagesCms.SELECT_GROUP}
      onChange={hashtagGroup}
    >
      {groups &&
        groups.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
